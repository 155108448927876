import { createSelector } from 'reselect';

const techProfilesStateSelector = (state) => state.getIn(['entities', 'techs', 'profile']);
const techBlockReasonsStateSelector = (state) => state.getIn(['entities', 'techs', 'blockReasons']);
const techMyWorkersStateSelector = (state) => state.getIn(['entities', 'techs', 'myWorkers']);
const techSuggestedWorkerStateSelector = (state) =>
  state.getIn(['entities', 'techs', 'suggestedWorkers']);

export const techMyWorkersStateJsSelector = createSelector(
  techMyWorkersStateSelector,
  (myWorkersState) => myWorkersState.toJS(),
);
export const techSuggestedWorkerStateJsSelector = createSelector(
  techSuggestedWorkerStateSelector,
  (suggestedWorkersState) => suggestedWorkersState.toJS(),
);

/**
 * NOTE:
 *
 * Since the type of keys for Maps matter, we're going to force the techId to be a number.
 */

// Profile
export const techProfilesSelector = createSelector(
  techProfilesStateSelector,
  (profiles) => profiles,
);

export const techProfileByIdSelector = (techId) =>
  createSelector(techProfilesStateSelector, (profiles) => {
    return techId && profiles.get(Number(techId));
  });

export const techProfileByIdJSSelector = (techId) =>
  createSelector(techProfilesStateSelector, (profiles) => {
    if (techId) {
      const techIdNumber = Number(techId);
      return profiles.get(techIdNumber) && profiles.get(techIdNumber).toJS();
    }
    return null;
  });

export const techProfileByIdOrSlugJSSelector = (techIdOrSlug) =>
  createSelector(techProfilesStateSelector, (profiles) => {
    // techIdOrSlug may be a String or a Number;
    const profilesJS = profiles.toJS() || {};

    const techIdNumber = Number(techIdOrSlug); // Number('iAmString') === NaN
    if (techIdNumber) {
      return profilesJS[techIdNumber] || null;
    }

    const matchingProfile = Object.values(profilesJS).find((p = {}) => p.slug === techIdOrSlug);
    if (matchingProfile) {
      return profilesJS[matchingProfile.id] || null;
    }

    return null;
  });

export const myWorkerCategoriesJSSelector = createSelector(
  techMyWorkersStateSelector,
  (myWorkers) => {
    const data = myWorkers.get('data');

    if (!data)
      return {
        all: [],
        favorites: [],
        blocked: [],
        past: [],
      };

    return {
      all: data.toJS() || [],
      favorites: data.filter((w) => w.get('favorite') === true).toJS() || [],
      blocked: data.filter((w) => w.get('blocked') === true).toJS() || [],
      past: data.filter((w) => w.get('past') === true).toJS() || [],
    };
  },
);

export const myWorkerBlockReasonsJSSelector = createSelector(
  techBlockReasonsStateSelector,
  (blockReasons) => blockReasons?.toJS() || [],
);

export const suggestedWorkersDataJsSelector = createSelector(
  techSuggestedWorkerStateJsSelector,
  (suggestedWorkers) => suggestedWorkers.data,
);
