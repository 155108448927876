export const PAGE_NAME = 'myPros';

export const DEEP_LINK_QUERY_PARAMS = {
  FAV_WORKER_ID: 'fav_worker_id',
  AUTH_TOKEN: 'auth_token',
};

export const BLOCK_MODAL_OTHER_REASON_CONFIG = {
  value: -1,
  reasonId: null,
};

export const BLOCK_MODAL_LABELS = {
  INPUT: 'Please provide a reason',
  DROPDOWN: 'Please provide a reason',
  ERROR: 'Please select a reason',
};
