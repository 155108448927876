import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useMatchParamsByKey } from 'src/containers/EV/ev.hooks';
import htDoorHorizontalLogo from 'src/images/ht_door_horizontal_logo.svg';
import useSearchParams from 'src/hooks/useSearchParams';
import { evPartnerCobrandImageSelector } from 'src/containers/EV/ev.selectors';
import { SKU_QUERY_PARAM_NAME } from 'src/containers/EV/ev.constants';
import styles from '../styles.scss';

const EVBookingHeader = () => {
  // TODO refactor as we go along
  const searchParams = useSearchParams();
  const { skuId: sid } = useMatchParamsByKey('/ev/:evPage/:skuId', {
    skuId: (key) => /^\d+$/.test(key),
  });
  const skuId = sid || searchParams.get(SKU_QUERY_PARAM_NAME) || null;
  const partnerImageUrl = useSelector(evPartnerCobrandImageSelector({ skuId }));

  return (
    <div className={cn('height100', styles.bgContainer)}>
      <div className="flex height100 alignItemsCenter justifyContentCenter">
        <img src={htDoorHorizontalLogo} className={styles.htLogo} alt="HelloTech Logo" />
        {partnerImageUrl && (
          <>
            <span className={styles.divider} />
            <img src={partnerImageUrl} className={styles.partnerLogo} alt="Partner Logo" />
          </>
        )}
      </div>
    </div>
  );
};

export default EVBookingHeader;
