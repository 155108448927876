import React from 'react';
// Assets && Components
import { Grid } from 'src/components/HTKit/Grid';
import targetLogo from 'src/images/branding/target-easyinstall.svg';
import htDoorHorizontalLogo from 'src/images/ht_door_horizontal_logo.svg';
// Styles
import styles from './styles.scss';

const TargetEasyInstallHeader = () => {
  return (
    <Grid.FullWidth classes={styles.grid}>
      <Grid.Row classes={styles.row}>
        <Grid.Column sm={2} md={4} lg={6} classes={styles.htLogoContainer}>
          <img src={htDoorHorizontalLogo} className={styles.htLogo} alt="HelloTech Logo" />
        </Grid.Column>
        <Grid.Column sm={2} md={4} lg={6}>
          <img src={targetLogo} className={styles.targetLogo} alt="Target Easy Install Logo" />
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

export default TargetEasyInstallHeader;

/*
  TargetEasyInstallHeader
    - Header to be shown for Target API order redemptions and PIN redemptions

  Note: Per Product actionableLogo is set to false, so not wrapping the component in a <Link /> component.
*/
