import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToFavoritesRequest,
  fetchMyWorkersRequest,
  removeFromFavoritesRequest,
  blockWorkerRequest,
  unblockWorkerRequest,
  fetchSuggestedWorkersRequest,
  invalidateMyWorkersData,
  invalidateSuggestedWorkersData,
} from 'src/components/Techs/data/actions';
import { myWorkerCategoriesJSSelector } from 'src/selectors/techs';

/** @param {{action: 'add' | 'remove' | 'block' | 'unblock', worker: {id: number, name: string}}} */
export const getSuccessMessage = ({ action = '', worker }) => {
  const [firstName] = worker.name.split(' ');
  switch (action) {
    case 'add':
      // Jamie added to your favorites!
      return `${firstName} added to your favorites!`;
    case 'remove':
      return `${firstName} removed from your favorites`;
    case 'block':
      return `${firstName} has been blocked`;
    case 'unblock':
      return `${firstName} has been unblocked`;
    default:
      return '';
  }
};

/**
 * A hook for managing worker relationships (favorites, blocked, past workers).
 * Provides filtered lists of workers, actions to modify relationships,
 * and convenience methods to check a worker's status.
 *
 * @param {{fetchOnLoad: boolean}} param
 */
export const useMyWorkers = ({ fetchOnLoad = true } = {}) => {
  const dispatch = useDispatch();

  /** @type {WorkerListCategories} */
  const myWorkers = useSelector(myWorkerCategoriesJSSelector) || [];

  // ################
  // ACTION HANDLERS
  // ################
  const fetchMyWorkers = useCallback(() => dispatch(fetchMyWorkersRequest()), [dispatch]);
  const invalidateMyWorkers = useCallback(() => dispatch(invalidateMyWorkersData()), [dispatch]);

  const addToFavorites = useCallback(
    /**
     * @param {FavoriteWorker} worker
     * @param {{orderId?: number | null}} [options]
     */
    (worker, { orderId = null } = {}) =>
      dispatch(
        addToFavoritesRequest({
          id: worker.id,
          withToast: true,
          successMsg: getSuccessMessage({ action: 'add', worker }),
          ...(orderId !== null && { orderId }),
        }),
      ),
    [dispatch],
  );
  const removeFromFavorites = useCallback(
    /** @param {FavoriteWorker} worker */
    (worker) =>
      dispatch(
        removeFromFavoritesRequest({
          id: worker.id,
          withToast: true,
          successMsg: getSuccessMessage({ action: 'remove', worker }),
        }),
      ),
    [dispatch],
  );

  /** @param {{worker: FavoriteWorker, blockReasonId: number, blockReasonText: string}} */
  const blockWorker = ({ worker, blockReasonId, blockReasonText }) => {
    dispatch(
      blockWorkerRequest({
        id: worker.id,
        blockReasonId,
        blockReasonText,
        withToast: true,
        successMsg: getSuccessMessage({ action: 'block', worker }),
      }),
    );
  };

  /** @param {{worker: FavoriteWorker}} */
  const unblockWorker = (worker) => {
    dispatch(
      unblockWorkerRequest({
        id: worker.id,
        withToast: true,
        successMsg: getSuccessMessage({ action: 'unblock', worker }),
      }),
    );
  };

  const fetchSuggestedWorkers = useCallback(() => dispatch(fetchSuggestedWorkersRequest()), [
    dispatch,
  ]);

  const invalidateSuggestedWorkers = useCallback(() => dispatch(invalidateSuggestedWorkersData()), [
    dispatch,
  ]);

  // ####################
  // CONVENIENCE METHODS
  // ####################
  const isWorkerInFavorites = useCallback(
    (id) => myWorkers.favorites.some((worker) => worker.id === id),
    [myWorkers.favorites],
  );
  const isWorkerBlocked = useCallback(
    (id) => myWorkers.blocked.some((worker) => worker.id === id),
    [myWorkers.blocked],
  );
  const isWorkerPast = useCallback((id) => myWorkers.past.some((worker) => worker.id === id), [
    myWorkers.past,
  ]);

  useEffect(() => {
    if (fetchOnLoad) {
      fetchMyWorkers();
    }
  }, [fetchMyWorkers, fetchOnLoad]);

  return {
    // Filtered Workers
    myWorkers,
    // Actions
    fetchMyWorkers,
    invalidateMyWorkers,
    addToFavorites,
    removeFromFavorites,
    blockWorker,
    unblockWorker,
    fetchSuggestedWorkers,
    invalidateSuggestedWorkers,
    // Convenience Methods
    isWorkerInFavorites,
    isWorkerBlocked,
    isWorkerPast,
  };
};

/** @typedef {import('src/types/techs').FavoriteWorker} FavoriteWorker */
/** @typedef {import('src/types/techs').WorkerListCategories} WorkerListCategories */
