import { takeLatest, select, call, put } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { displayErrorsWithSnack } from 'src/utils/request';
import { requestStarted, requestFinished } from 'src/actions/common';
import { getSuccessMessage } from 'src/containers/Account/MyProsPage/hooks/useMyWorkers';
import { htToast } from 'HTKit/Toast';
import {
  FETCH_MY_WORKERS_REQUEST,
  ADD_TO_FAVORITES_REQUEST,
  REMOVE_FROM_FAVORITES_REQUEST,
  ADD_WORKER_FROM_DEEP_LINK,
  FETCH_BLOCK_REASONS_REQUEST,
  BLOCK_WORKER_REQUEST,
  UNBLOCK_WORKER_REQUEST,
  FETCH_SUGGESTED_WORKERS_REQUEST,
} from './constants';
import {
  fetchMyWorkersRequest,
  fetchMyWorkersSuccess,
  fetchMyWorkersFailure,
  fetchBlockReasonsSuccess,
  fetchSuggestedWorkersSuccess,
  fetchSuggestedWorkersFailure,
} from './actions';

/** Front load items to avoid using pagination */
const FRONT_LOAD_ITEM_QUANTITY = Number.MAX_SAFE_INTEGER;

/** These need to be sent as query params to get the entire list of workers. */
const DEFAULT_WORKER_LIST_FILTERS = {
  blocked: 'include',
  past: true,
  favorite: 'include',
};

// ################
// FETCH MY WORKERS
// ################

/**
 * Retrieves the user's associated workers list including past workers,
 * favorites, and blocked workers.
 * @generator
 */
function* handleFetchMyWorkersRequest() {
  const user = yield select((state) => state.getIn(['user']));

  if (!user) {
    yield put(fetchMyWorkersFailure());
    return;
  }

  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.techs.myWorkers, {
    ...DEFAULT_WORKER_LIST_FILTERS,
    pagination: { per_page: FRONT_LOAD_ITEM_QUANTITY },
  });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    yield put(fetchMyWorkersFailure());
    return;
  }

  const { techs } = response.data;

  // Update store
  yield put(fetchMyWorkersSuccess({ workers: techs }));
}

// #############
// ADD FAVORITE
// #############

/**
 * Adds a worker to the user's favorites list.
 * @generator
 * @param {Object} action - The Redux action
 * @param {Object} action.payload - Action payload
 * @param {number|string} action.payload.id - Worker ID to favorite
 * @param {string} [action.payload.successMsg] - Optional success message
 * @param {boolean} [action.payload.withToast] - Whether to show notification
 */
function* handleAddToFavoritesRequest(action) {
  const { id, successMsg, withToast, orderId } = action.payload;
  const requestParams = { ...(orderId && { order_id: orderId }) };

  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.techs.addFavorite, { id }, requestParams);
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }
  if (withToast && successMsg) {
    htToast(successMsg);
  }
  yield put(fetchMyWorkersRequest());
}

// ################
// REMOVE FAVORITE
// ################

/**
 * Removes a worker from the user's favorites list.
 * @generator
 * @param {Object} action - The Redux action
 * @param {Object} action.payload - Action payload
 * @param {number|string} action.payload.id - Worker ID to unfavorite
 * @param {string} [action.payload.successMsg] - Optional success message
 * @param {boolean} [action.payload.withToast] - Whether to show notification
 */
function* handleRemoveFromFavoritesRequest(action) {
  const { id, successMsg, withToast } = action.payload;

  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.techs.unfavorite, { id });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }

  if (withToast && successMsg) {
    htToast(successMsg);
  }
  yield put(fetchMyWorkersRequest());
}

// ################
// ADD FROM DEEP LINK
// ################

/**
 * Handles favoriting a worker from a deep link context.
 * @generator
 * @param {Object} action - The Redux action
 * @param {Object} action.payload - Action payload
 * @param {number|string} action.payload.id - Worker ID to favorite
 * @param {boolean} [action.payload.withToast=true] - Whether to show notification
 * @param {Function} [action.payload.onSuccess=()=>{}] - Success callback
 */
function* handleAddWorkerFromDeepLink(action) {
  const { id, withToast = true, onSuccess = () => {} } = action.payload;

  if (!id) return;

  const routes = yield call(selectRoutes);

  // -------------------------
  // 1. Get current worker list
  // -------------------------
  yield put(requestStarted());
  const allWorkersResponse = yield call(routes.techs.myWorkers, {
    ...DEFAULT_WORKER_LIST_FILTERS,
    pagination: { per_page: FRONT_LOAD_ITEM_QUANTITY },
  });
  yield put(requestFinished());

  if (allWorkersResponse.err) {
    yield put(displayErrorsWithSnack(allWorkersResponse));
    return;
  }

  /** @type {{techs:FavoriteWorker[]}} */
  const { techs } = allWorkersResponse.data;
  yield put(fetchMyWorkersSuccess({ workers: techs }));

  // -----------------------
  // 2. Validate worker state
  // -----------------------
  const worker = techs.find((t) => t.id === id) || null;
  const isWorkerBlocked = worker?.blocked;
  const isWorkerInFavorites = worker?.favorite;

  // Exit if worker is blocked
  if (isWorkerBlocked) {
    const [firstName] = worker.name.split(' ');
    htToast(`${firstName} is blocked and cannot be favorited`);
    onSuccess();
    return;
  }

  // Exit if worker is already in favorites
  if (isWorkerInFavorites) {
    onSuccess();
    return;
  }

  // -------------------------------
  // 3. Add worker to favorites list
  // -------------------------------
  yield put(requestStarted());
  const additionResponse = yield call(routes.techs.addFavorite, { id });
  yield put(requestFinished());

  if (additionResponse.err) {
    yield put(displayErrorsWithSnack(additionResponse));
    return;
  }

  // Show success message
  if (withToast) {
    const { tech } = additionResponse.data;
    const workerObj = { name: `${tech.first_name} ${tech.last_name}` };
    const successMsg = getSuccessMessage({ action: 'add', worker: workerObj });
    htToast(successMsg);
  }

  onSuccess();

  // Refresh worker list
  yield put(fetchMyWorkersRequest());
}

// ################
// BLOCK WORKER
// ################
function* handleFetchBlockReasonsRequest() {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.techs.getBlockReasons);
  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }
  const { block_reasons } = response.data;
  yield put(fetchBlockReasonsSuccess({ blockReasons: block_reasons }));
}

function* handleBlockWorkerRequest(action) {
  const { id, blockReasonId, blockReasonText, successMsg, withToast } = action.payload;

  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(
    routes.techs.block,
    { id },
    { block_reason_id: blockReasonId, block_reason_text: blockReasonText },
  );
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }

  if (withToast && successMsg) {
    htToast(successMsg);
  }

  yield put(fetchMyWorkersRequest());
}

function* handleUnblockWorkerRequest(action) {
  const { id, successMsg, withToast } = action.payload;

  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.techs.unblock, { id });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }

  if (withToast && successMsg) {
    htToast(successMsg);
  }

  yield put(fetchMyWorkersRequest());
}

// #################
// SUGGESTED WORKERS
// #################
function* handleFetchSuggestedWorkersRequest() {
  const user = yield select((state) => state.getIn(['user']));
  const cart = yield select((state) => state.getIn(['entities', 'cart']));

  if (!user || !cart.get('id')) {
    yield put(fetchSuggestedWorkersFailure());
    return;
  }

  const routes = yield call(selectRoutes);
  const response = yield call(routes.techs.getSuggestedTechs, { cart_id: cart.get('id') });
  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    yield put(fetchSuggestedWorkersFailure());
    return;
  }
  const { techs } = response.data;
  yield put(fetchSuggestedWorkersSuccess({ workers: techs }));
}

function* techDataSagas() {
  yield takeLatest(FETCH_MY_WORKERS_REQUEST, handleFetchMyWorkersRequest);
  yield takeLatest(ADD_TO_FAVORITES_REQUEST, handleAddToFavoritesRequest);
  yield takeLatest(REMOVE_FROM_FAVORITES_REQUEST, handleRemoveFromFavoritesRequest);
  yield takeLatest(ADD_WORKER_FROM_DEEP_LINK, handleAddWorkerFromDeepLink);
  yield takeLatest(FETCH_BLOCK_REASONS_REQUEST, handleFetchBlockReasonsRequest);
  yield takeLatest(BLOCK_WORKER_REQUEST, handleBlockWorkerRequest);
  yield takeLatest(UNBLOCK_WORKER_REQUEST, handleUnblockWorkerRequest);
  yield takeLatest(FETCH_SUGGESTED_WORKERS_REQUEST, handleFetchSuggestedWorkersRequest);
}

export default [techDataSagas];

/** @typedef {import('src/types/techs').FavoriteWorker} FavoriteWorker */
