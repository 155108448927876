import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useWithStyles from 'src/hooks/useWithStyles';
import styles from './styles.scss';

const PanelV2 = (props) => {
  const { children, className, ...rest } = props;

  const panelClasses = cn(styles.panelContainer, useWithStyles(props, styles), {
    [className]: className,
  });

  if (!children) return null;

  return (
    <section className={panelClasses} {...rest}>
      {children}
    </section>
  );
};

PanelV2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ignoreGridMarginsMobile: PropTypes.bool,
  noShadow: PropTypes.bool,
  lowShadow: PropTypes.bool,
  lightShadow: PropTypes.bool, // To Do - Migrate to lowShadow. Admin is using lowShadow
  mediumShadow: PropTypes.bool,
  heavyShadow: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  smallBorderRadius: PropTypes.bool,
  mediumBorderRadius: PropTypes.bool,
  largeBorderRadius: PropTypes.bool,
};

export default PanelV2;
