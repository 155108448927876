import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { PanelV2 } from '../HTKit/Elements/PanelV2';
import { GladlyKnowledgeBase } from './GladlyKnowledgeBase';
import { KNOWLEDGE_BASE_TYPES } from './useGladlyKnowledgeBase';
import styles from './styles.scss';

export const GladlyKnowledgeBaseContainer = ({ className, type }) => {
  return (
    <div className={cn(styles.gkbPanelContainer, className)}>
      <PanelV2 className={styles.panel} noShadow>
        <GladlyKnowledgeBase type={type} />
      </PanelV2>
    </div>
  );
};

GladlyKnowledgeBaseContainer.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(KNOWLEDGE_BASE_TYPES)),
};
