import { takeLatest, put, call } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
// Actions
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { selectWorkerStageSubmitted } from './actions';
// Constants
import { SELECT_PREFERRED_WORKER } from './constants';

/** @param {{payload: { id: number | null }}} action */
function* handleSelectPreferredWorker(action) {
  const { id } = action.payload || {};

  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.cart.setPreferredWorker, { preferred_tech_id: id });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
    return;
  }

  // Update store with new cart
  const newCart = response.data.cart;
  yield put(updateCart({ cart: newCart }));

  // Navigate to next stage
  yield put(selectWorkerStageSubmitted());
}

function* selectWorkerPageSaga() {
  yield takeLatest(SELECT_PREFERRED_WORKER, handleSelectPreferredWorker);
}

export default [selectWorkerPageSaga];
