import { WALMART_THEME } from 'src/utils/branding/constants';
import { isProduction } from 'src/utils/env';
import { faqPath } from 'src/utils/paths';

export const GLADLY_CONTACT_FORM_LINK = 'https://latch.formstack.com/forms/contact_us';
/**
 * Add your FAQ urls here
 * @type {Readonly<{default: string}>}
 */

export const IFRAME_SRCS = Object.freeze({
  default: 'https://help.hellotech.com/',
  contactUs: GLADLY_CONTACT_FORM_LINK,
  /*
    The walmart FAQ was used for Walmart branded customers (e.g. Walmart Subscription holders).
    We're not selling Walmart subscriptions anymore, but will leave the link here for now -GH Nov 15, 2021
  */
  [WALMART_THEME]: faqPath(),
  privacyPolicy: `https://content${isProduction() ? '' : 's'}.hellotech.com/privacy-policy`,
  membershipGuarantee:
    'https://help.hellotech.com/en_us/how-does-the-no-risk-hellotech-membership-guarantee-work-HJJd49hXI',
});

export const getIframeSource = (src = 'default') => `${IFRAME_SRCS[src]}`;

export const CALLER_PAGE = 'faq';

export const SRC_TYPES = {
  PRIVACY_POLICY: 'privacyPolicy',
  CONTACT_US: 'contactUs',
  MEMBERSHIP_GUARANTEE: 'membershipGuarantee',
};

export const FAQ_PAGE_CONTAINER_ID = 'FAQPageContainer';
export const FAQ_PAGE_IFRAME_ID = 'FAQIframeContainer';

export const FAQ_DISCLAIMER =
  '*By texting us, you consent to receive text messages from HelloTech at the mobile number you used to text and you are opting-in to receive future messages or a phone call in the number you provided. Message & Data rates may apply. Reply STOP anytime to opt-out.';
