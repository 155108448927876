export const PAGE_NAME = 'booking';
export const STAGE_LOADED = 'bookingPage/STAGE_LOADED';
export const GO_TO_STAGE = 'bookingPage/GO_TO_STAGE';
export const TOGGLE_SAME_DAY_SERVICE_INFO = 'bookingPage/TOGGLE_SAME_DAY_SERVICE_INFO';
export const CREATE_PASSWORDLESS_ACCOUNT = 'bookingPage/CREATE_PASSWORDLESS_ACCOUNT';

// values pulled from BE
export const BOOKING_STAGES = Object.freeze({
  VERIFICATION: 'verification', // exists on FE only
  ADDRESS: 'address',
  SELECT_WORKER: 'select-worker',
  AVAILABILITY: 'availability',
  PAYMENT: 'payment',
  SUMMARY: 'summary',
});
export const BREADCRUMB_STAGES_DEFAULT = Object.freeze([
  { name: BOOKING_STAGES.VERIFICATION, step: 1 },
  { name: BOOKING_STAGES.ADDRESS, step: 2 },
  { name: BOOKING_STAGES.AVAILABILITY, step: 3 },
  { name: BOOKING_STAGES.PAYMENT, step: 4 },
  { name: BOOKING_STAGES.SUMMARY, step: 5 },
]);

export const BREADCRUMB_STAGES_NO_AVAILABILITY = Object.freeze([
  { name: BOOKING_STAGES.VERIFICATION, step: 1 },
  { name: BOOKING_STAGES.ADDRESS, step: 2 },
  { name: BOOKING_STAGES.PAYMENT, step: 3 },
  { name: BOOKING_STAGES.SUMMARY, step: 4 },
]);

export const BREADCRUMB_STAGES_SELECT_WORKER = Object.freeze([
  { name: BOOKING_STAGES.VERIFICATION, step: 1 },
  { name: BOOKING_STAGES.ADDRESS, step: 2 },
  { name: BOOKING_STAGES.SELECT_WORKER, step: 3 },
  { name: BOOKING_STAGES.AVAILABILITY, step: 4 },
  { name: BOOKING_STAGES.PAYMENT, step: 5 },
  { name: BOOKING_STAGES.SUMMARY, step: 6 },
]);

export const INJECTED_USER_PHONE = '1112223333';
