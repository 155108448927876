import { takeEvery } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { PAGE_NAME } from './constants';

function* handleLoadPage() {
  yield loadUserProfileSaga(PAGE_NAME, true);
}

function* myProsPageSaga() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === PAGE_NAME,
    handleLoadPage,
  );
}

export default [myProsPageSaga];
