import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import htDoorHorizontalLogo from 'src/images/ht_door_horizontal_logo.svg';
import { Picture } from 'src/components/Elements/Picture';
import styles from './styles.scss';

/**
 * HTLogo
 *
 * Only to be used in headers.
 */
const HTLogo = ({ className = '', defaultCursor = false }) => {
  const logoStyles = cn(
    styles.htLogo,
    {
      [styles.defaultCursor]: defaultCursor,
      topNavHelloTechLogo: true,
    },
    className,
  );

  return <Picture src={htDoorHorizontalLogo} className={logoStyles} />;
};

HTLogo.propTypes = {
  className: PropTypes.string,
  defaultCursor: PropTypes.bool,
};

export default HTLogo;
