import queryString from 'query-string';
import get from 'lodash/get';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import {
  push,
  replace,
  accountPath,
  loginPath,
  signInPath,
  homePath,
  cartPath,
  bookingStagePath,
  orderConfirmationPath,
  completeOrderPath,
} from 'src/utils/paths';
import { selectRoutes } from 'src/apiRoutes';
import { displayErrors } from 'src/utils/request';
import { STATUS, STATUS_ID } from 'src/constants/cart';
import { LOGGED_IN } from 'src/containers/LoginPage/LoginForm/constants';
import { USER_LOGGED_OUT } from 'src/containers/AppBase/constants';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { START_BOOKING } from 'src/containers/CartPage/constants';
import { togglePlanHolderModal } from 'src/containers/CartPage/actions';
import { goToStage } from 'src/containers/BookingPage/actions';
import { STAGE_LOADED, GO_TO_STAGE, BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { AVAILABILITY_SUBMITTED } from 'src/containers/BookingPage/AvailabilityPage/constants';
import { ADDRESS_SUBMITTED } from 'src/containers/BookingPage/AddressPage/constants';
import { SELECT_WORKER_STAGE_SUBMITTED } from 'src/containers/BookingPage/SelectWorkerPage/constants';
import { PAYMENT_SUBMITTED } from 'src/containers/BookingPage/PaymentPage/constants';
import { SUMMARY_SUBMITTED } from 'src/containers/BookingPage/SummaryPage/constants';
import { PASSWORD_CREATED } from 'src/containers/CreatePasswordPage/constants';
import { allowStage } from 'src/containers/BookingPage/utils';
import { loginRedirect } from 'src/containers/LoginPage/LoginForm/actions';
import {
  cartHasEVWorkflowSelector,
  cartHasEVInstallOnlyWorkflowSelector,
} from 'src/containers/EV/ev.selectors';
import { buildEVPaths } from 'src/containers/EV/utils';
import { nextPathnameSelector, paramsSelector, locationSearchSelector } from 'src/selectors/router';
import ROUTES from 'src/routes';
import { memoizedCreateCartFlow } from 'src/utils/cartFlow';
import { immutableToJS } from 'src/utils/helpers';
import { myWorkerCategoriesJSSelector, suggestedWorkersDataJsSelector } from 'src/selectors/techs';
import { getDirectBookingTechId } from 'src/utils/cookies/directBookingCookie';

/**
 * Detects if a plan holder is trying to add a second subscription.
 * The backend removes the original plan when this happens, so we
 * check if a plan existed before but not after cart update.
 *
 * @param {Object} oldCart - Cart before update
 * @param {Object} newCart - Cart after update
 * @return {boolean} True if second subscription attempt detected
 */
const isPlanHolderAttemptingSecondSubscription = (oldCart = {}, newCart = {}) =>
  oldCart.plan && !newCart.plan;

function* ensureCouponValid() {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.ensureCouponValid);
  if (!response.err) {
    yield put(updateCart({ cart: response.data.cart }));
  }
}

/**
 * Manages post-login actions and navigation.
 *
 * This saga:
 * 1. Fetches and updates the user's cart
 * 2. Validates any applied coupons
 * 3. Determines the appropriate redirect based on:
 *    - API-originated orders
 *    - Existing redirect paths
 *    - Current page and cart status
 * 4. Navigates the user to the correct page or cart stage
 */
function* handleLoggedIn(action) {
  const { payload = {} } = action;
  const {
    /** True if user is trying to login from the Booking Verification page */
    isPasswordlessCheckout,
  } = payload;

  // Fetch the user's cart
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.find, { normalize: true });
  const oldCart = yield select((state) => state.getIn(['entities', 'cart']));
  const {
    data: { cart },
    err,
  } = response;
  if (!err) {
    yield put(updateCart({ cart }));
  } else {
    yield put(displayErrors(response));
    return;
  }

  // Validate any applied coupons
  yield call(ensureCouponValid);

  // Gather navigation-related information
  const redirectPath = yield select((state) => state.getIn(['pages', 'login', 'redirectPath']));
  const nextPathname = yield select(nextPathnameSelector);
  const currentPath = yield select((state) => state.getIn(['router', 'location', 'pathname']));

  // Handle API-originated orders
  if (cart && cart.order && cart.order.fromApi) {
    const url = completeOrderPath();
    const query = yield select(paramsSelector);
    yield put(push(queryString.stringifyUrl({ url, query })));
    return;
  }

  if (isPasswordlessCheckout) {
    // If they're trying to add a second plan the BE will remove it. Bounce them to cart page.
    if (isPlanHolderAttemptingSecondSubscription(oldCart.toJS(), cart)) {
      yield put(goToStage('items', true));
      yield put(togglePlanHolderModal(true)); // show notificaiton that they can't add another subscription
    } else {
      yield put(goToStage(cart.status, false));
    }
  }
  // Handle redirects based on login context and cart status
  else if (redirectPath) {
    /*
      The BE may send queryParms with a redirect path. If we want to override or intercept
      that behavior we do that here.
    */
    yield put(loginRedirect(null));
    /* This next if/else block is the default behavior for any user with an active cart */
    if (cart && cart.status) {
      if (isPlanHolderAttemptingSecondSubscription(oldCart.toJS(), cart)) {
        yield put(goToStage('items', true));
        yield put(togglePlanHolderModal(true)); // show notificaiton that they can't add another subscription
      }

      /** Handle query param redirects to a specific booking stage */
      const bookingStages = Object.values(BOOKING_STAGES).map((stage) => stage.toLowerCase());

      if (bookingStages.includes(redirectPath.toLowerCase())) {
        yield put(goToStage(redirectPath, true));
      }
    } else {
      yield put(goToStage(redirectPath, true));
    }
  } else if (nextPathname) {
    yield put(replace(nextPathname));
  } else if (currentPath.includes(loginPath())) {
    yield put(replace(accountPath));
  } else if (currentPath.includes(signInPath()) && cart?.items.length) {
    yield put(goToStage('verification'));
  } else {
    // default to accountPath
    yield put(replace(accountPath));
  }
}

/**
 * Initiates the booking process and manages navigation.
 *
 * This saga fetches the latest cart and determines the appropriate
 * navigation based on user authentication status and cart state.
 *
 * It handles different scenarios for authenticated and unauthenticated users,
 * including support for passwordless authentication.
 */
function* handleStartBooking(action) {
  const {
    /** Provide a BOOKING_STAGES value to force navigation to that stage */
    stage,
    /** True if passwordless auth is enabled */
    isPasswordless = false,
  } = action;
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.startBooking);

  let cart;
  if (!response.err) {
    cart = response.data.cart;
    yield put(updateCart({ cart }));
  } else {
    yield put(displayErrors(response));
  }

  const user = yield select((state) => state.get('user'));
  yield put(loginRedirect(null));

  if (user) {
    if (cart && cart.status) {
      const nextStage = stage || cart.status;
      yield put(goToStage(nextStage));
    } else {
      yield put(goToStage(BOOKING_STAGES.AVAILABILITY));
    }
  } else if (isPasswordless) {
    // Handle unauthenticated user with passwordless auth flow enabled
    // Skip sign-in and navigate directly to verification stage
    yield put(goToStage(BOOKING_STAGES.VERIFICATION));
  } else {
    yield put(loginRedirect(BOOKING_STAGES.AVAILABILITY));
    yield put(push(ROUTES.SIGN));
  }
}

function* handlePasswordCreated() {
  const redirectPath = yield select((state) => state.getIn(['pages', 'login', 'redirectPath']));
  const nextPathname = yield select(nextPathnameSelector);

  if (redirectPath) {
    yield put(loginRedirect(null));
    yield put(goToStage(redirectPath));
  } else if (nextPathname) {
    yield put(replace(nextPathname));
  } else {
    yield put(goToStage('availability'));
  }
}

function* handleUserLoggedOut() {
  yield put(push(homePath));
}

function* handleStageLoaded(action) {
  const { stage } = action;
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  const user = yield select((state) => state.get('user'));
  const locationSearch = yield select(locationSearchSelector);
  const { override } = queryString.parse(queryString.extract(locationSearch));

  if (
    !allowStage({
      user,
      stage,
      cart,
      allowOverrideStage: override,
      authOverride: stage === 'verification',
    })
  ) {
    yield put(push(cartPath));
  }
}

/** Determine if the payment screen should be skipped for a partner order */
export const skipPartnerPayment = (cart) => {
  /**
   * cart.partner === true if there are partner items (services) in the cart
   *
   * cart.plan.partner === true if there is a partner plan in the cart
   */
  const isPartnerCart = get(cart, 'partner', false) || get(cart, 'plan.partner', false);
  return isPartnerCart && get(cart, 'breakdown.total', false) === 0;
};

/**
 * Resolves navigation flow between frontend and backend requirements for the booking process.
 *
 * - Backend controls cart.status which typically drives routing to /cart/booking/{cart.status}
 * - Frontend may need additional validation steps or different routing based on business rules
 * - Used to force address verification for all orders, specifically:
 *   1. Walmart API orders where warehouse addresses are incorrectly set
 *   2. Partner orders that need address confirmation
 *
 * @param {Object} options
 * @param {string} options.stage - Current booking stage from cart.status
 * @param {Object} options.cart - Cart data from BE
 * @param {Object} options.route - Current routing information
 * @returns {string} - The resolved booking stage to navigate to
 *
 * Technical Debt:
 * - Flow control should be unified in either BE or FE, not split
 * - Current implementation is a temporary solution for product requirements
 */
function resolveBookingStageFlow({ stage = '', route = {}, cart, suggestedWorkers, myWorkers }) {
  const currentLocation = get(route, 'location.pathname', '');
  const previousLocation = get(route, 'previousLocation', '');

  /**
   * Checks if user is currently on or navigating from any of the specified pages
   *
   * @param {...string} paths - Page paths to check against (e.g., 'address', 'summary')
   * @returns {boolean} True if current or previous location includes any of the paths
   *
   * Example:
   * // Returns true if user is on or coming from address or summary pages
   * isOnOrComingFromPages('address', 'summary')
   */
  const isOnOrComingFromPages = (...paths) => {
    return paths.some((path) =>
      [currentLocation, previousLocation].some((location) => {
        return location.includes(path);
      }),
    );
  };

  /** Check if this booking was initiated from a worker's landing page */
  const directBookingTechId = getDirectBookingTechId();
  const { shouldShowWorkerSelection } = memoizedCreateCartFlow({
    cart: immutableToJS(cart),
    suggestedWorkers,
    myWorkers,
    isDirectBooking: Boolean(directBookingTechId),
  });

  /** Handles stage resolution for worker selection flow when cart.status is 'availability' */
  const handleSelectWorkerAvailabilityStage = () => {
    // Go to availability stage
    if (isOnOrComingFromPages(BOOKING_STAGES.SELECT_WORKER, BOOKING_STAGES.SUMMARY)) return stage;

    // Go to select worker stage
    if (isOnOrComingFromPages(BOOKING_STAGES.ADDRESS)) return BOOKING_STAGES.SELECT_WORKER;

    // Go to address stage
    return BOOKING_STAGES.ADDRESS;
  };

  return (() => {
    switch (stage) {
      /*
       * App tells us to go to availability/scheduling, but it will only do that IF we've already
       * filled out an address. But, we want to confirm our address.
       * -> Inject address unless we are already on address or coming from summary page (edit mode)
       */
      case BOOKING_STAGES.AVAILABILITY:
        if (shouldShowWorkerSelection()) {
          return handleSelectWorkerAvailabilityStage();
        }
        if (isOnOrComingFromPages(BOOKING_STAGES.ADDRESS, BOOKING_STAGES.SUMMARY)) {
          // Go to availability stage
          return stage;
        }
        // Go to address stage
        return BOOKING_STAGES.ADDRESS;
      /*
       * App tells us to go to summary, but it will only do that IF we've already
       * filled out a payment. But, we want to confirm our payment.
       * -> Inject payment unless we are already on payment or coming from summary page (edit mode)
       */
      case BOOKING_STAGES.SUMMARY:
        if (
          isOnOrComingFromPages(BOOKING_STAGES.PAYMENT, BOOKING_STAGES.SUMMARY) ||
          skipPartnerPayment(cart)
        ) {
          // Go to summary stage
          return stage;
        }
        // Go to payment stage
        return BOOKING_STAGES.PAYMENT;

      default:
        return stage; // Continue to the stage that was passed to this function
    }
  })();
}

function* handleGoToStage(action) {
  const { stage, hard, params } = action;
  const cart = yield select((state) => state.getIn(['entities', 'cart']).toJS());
  const route = yield select((state) => state.get('beforeRouteTransition').toJS());
  const suggestedWorkers = yield select(suggestedWorkersDataJsSelector);
  const myWorkers = yield select(myWorkerCategoriesJSSelector);
  const method = hard ? replace : push;

  let path;

  switch (stage) {
    case 'items':
      path = cartPath();
      break;
    case 'complete-partner-order':
      path = '/complete-partner-order';
      break;
    default: {
      const resolvedStage = resolveBookingStageFlow({
        stage,
        cart,
        route,
        suggestedWorkers,
        myWorkers,
      });
      path = bookingStagePath(resolvedStage);
    }
  }
  yield put(method(path, params));
}

/**
 * Creates a saga to handle booking stage submissions within the checkout flow.
 * Manages stage transitions based on cart status and special workflows like EV,
 * determining whether to proceed to the next stage or stay on the current stage.
 */
function createBookingStageHandler(stageStatusId) {
  return function* handleBookingStageSubmission(action) {
    // `updated === false` indicates first-time saving of values for this stage
    // `updated === true` indicates modifying previously saved values
    const { updated } = action;
    const cart = yield select((state) => state.getIn(['entities', 'cart']));
    const cartHasEVWorkflow = yield select(cartHasEVWorkflowSelector);
    const cartHasEVInstallOnlyWorkflow = yield select(cartHasEVInstallOnlyWorkflowSelector);

    let nextStage;

    // ================================================
    // Determine next stage based on edit/submit context
    // ================================================
    if (updated) {
      // Default behavior: progress to next stage in checkout flow
      nextStage = STATUS[stageStatusId + 1];

      // Handle "Edit" use case from Summary page
      // If customer is editing from Summary, return them there instead of progressing
      // This preserves the customer's position in checkout rather than forcing them
      // through stages they've already completed
      if (cart.get('status') === 'summary') nextStage = 'summary';
    } else {
      nextStage = cart.get('status');
    }

    // =====================================
    // Handle special routing and navigate
    // =====================================
    const shouldRedirectToEVReviewPage =
      (cartHasEVWorkflow || cartHasEVInstallOnlyWorkflow) && stageStatusId === STATUS_ID.PAYMENT;

    if (shouldRedirectToEVReviewPage) {
      const token = cart.get('token');
      yield put(push(buildEVPaths({ pathType: 'review', token })));
      return;
    }

    yield put(goToStage(nextStage));
  };
}

function* handleSummarySubmitted(action) {
  const { orderId } = action;
  yield put(push(orderConfirmationPath(orderId)));
}

function* handleSelectWorkerStageSubmitted() {
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  const nextStage = cart.get('status');
  yield put(goToStage(nextStage));
}

/**
 * Top-level saga orchestrating app navigation and state transitions.
 * Handles authentication flows, booking stage progression, and major page
 * transitions across the application.
 */
function* pageFlow() {
  yield takeLatest(LOGGED_IN, handleLoggedIn);
  yield takeLatest(START_BOOKING, handleStartBooking);
  yield takeLatest(USER_LOGGED_OUT, handleUserLoggedOut);
  yield takeLatest(STAGE_LOADED, handleStageLoaded);
  yield takeLatest(GO_TO_STAGE, handleGoToStage);
  yield takeLatest(ADDRESS_SUBMITTED, createBookingStageHandler(STATUS_ID.ADDRESS));
  yield takeLatest(AVAILABILITY_SUBMITTED, createBookingStageHandler(STATUS_ID.AVAILABILITY));
  yield takeLatest([PAYMENT_SUBMITTED], createBookingStageHandler(STATUS_ID.PAYMENT));
  yield takeLatest(SUMMARY_SUBMITTED, handleSummarySubmitted);
  yield takeLatest(SELECT_WORKER_STAGE_SUBMITTED, handleSelectWorkerStageSubmitted);
  yield takeLatest(PASSWORD_CREATED, handlePasswordCreated);
}

export default [pageFlow];
