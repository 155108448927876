import { fromJS } from 'immutable';
import {
  LOAD_PROFILE_DATA,
  FETCH_STATUSES,
  FETCH_MY_WORKERS_REQUEST,
  FETCH_MY_WORKERS_SUCCESS,
  FETCH_MY_WORKERS_FAILURE,
  INVALIDATE_MY_WORKERS_DATA,
  FETCH_BLOCK_REASONS_SUCCESS,
  FETCH_SUGGESTED_WORKERS_REQUEST,
  FETCH_SUGGESTED_WORKERS_SUCCESS,
  FETCH_SUGGESTED_WORKERS_FAILURE,
  INVALIDATE_SUGGESTED_WORKERS_DATA,
} from './constants';

const initialState = fromJS({
  profile: {},
  myWorkers: {
    data: [],
    fetchStatus: FETCH_STATUSES.IDLE,
  },
  blockReasons: [],
  suggestedWorkers: {
    data: [],
    fetchStatus: FETCH_STATUSES.IDLE,
  },
});

const techDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE_DATA: {
      const { data, techId } = action;
      if (!techId) return state;
      /**
       * Note: type of the keys matter. we're going to force the keys to be a number.
       */
      const techIdNumber = Number(techId);
      return state.mergeIn(['profile', techIdNumber], fromJS(data));
    }
    case FETCH_MY_WORKERS_REQUEST: {
      return state.setIn(['myWorkers', 'fetchStatus'], FETCH_STATUSES.LOADING);
    }
    case FETCH_MY_WORKERS_SUCCESS: {
      const { workers } = action.payload;
      return state
        .setIn(['myWorkers', 'data'], fromJS(workers))
        .setIn(['myWorkers', 'fetchStatus'], FETCH_STATUSES.SUCCEEDED);
    }
    case FETCH_MY_WORKERS_FAILURE: {
      return state.setIn(['myWorkers', 'fetchStatus'], FETCH_STATUSES.FAILURE);
    }
    case INVALIDATE_MY_WORKERS_DATA: {
      return state
        .setIn(['myWorkers', 'data'], fromJS([]))
        .setIn(['myWorkers', 'fetchStatus'], FETCH_STATUSES.IDLE);
    }
    case FETCH_BLOCK_REASONS_SUCCESS: {
      const { blockReasons } = action.payload;
      return state.set('blockReasons', fromJS(blockReasons));
    }
    case FETCH_SUGGESTED_WORKERS_REQUEST: {
      return state.setIn(['suggestedWorkers', 'fetchStatus'], FETCH_STATUSES.LOADING);
    }
    case FETCH_SUGGESTED_WORKERS_SUCCESS: {
      const { workers } = action.payload;
      return state
        .setIn(['suggestedWorkers', 'data'], fromJS(workers))
        .setIn(['suggestedWorkers', 'fetchStatus'], FETCH_STATUSES.SUCCEEDED);
    }
    case FETCH_SUGGESTED_WORKERS_FAILURE: {
      return state.setIn(['suggestedWorkers', 'fetchStatus'], FETCH_STATUSES.FAILURE);
    }
    case INVALIDATE_SUGGESTED_WORKERS_DATA: {
      return state
        .setIn(['suggestedWorkers', 'data'], fromJS([]))
        .setIn(['suggestedWorkers', 'fetchStatus'], FETCH_STATUSES.IDLE);
    }
    default:
      return state;
  }
};

export default techDataReducer;
