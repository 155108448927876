export const FETCH_STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILURE: 'failure',
};

// Redux Actions
export const LOAD_PROFILE_DATA = 'techData/LOAD_PROFILE_DATA';

export const FETCH_MY_WORKERS_REQUEST = 'techData/FETCH_MY_WORKERS_REQUEST';
export const FETCH_MY_WORKERS_SUCCESS = 'techData/FETCH_MY_WORKERS_SUCCESS';
export const FETCH_MY_WORKERS_FAILURE = 'techData/FETCH_MY_WORKERS_FAILURE';
export const INVALIDATE_MY_WORKERS_DATA = 'techData/INVALIDATE_MY_WORKERS_DATA';

export const ADD_TO_FAVORITES_REQUEST = 'techData/ADD_TO_FAVORITES_REQUEST';

export const REMOVE_FROM_FAVORITES_REQUEST = 'techData/REMOVE_FROM_FAVORITES_REQUEST';

export const ADD_WORKER_FROM_DEEP_LINK = 'techData/ADD_WORKER_FROM_DEEP_LINK';

export const FETCH_BLOCK_REASONS_REQUEST = 'techData/FETCH_BLOCK_REASONS_REQUEST';
export const FETCH_BLOCK_REASONS_SUCCESS = 'techData/FETCH_BLOCK_REASONS_SUCCESS';

export const BLOCK_WORKER_REQUEST = 'techData/BLOCK_WORKER_REQUEST';

export const UNBLOCK_WORKER_REQUEST = 'techData/UNBLOCK_WORKER_REQUEST';

export const FETCH_SUGGESTED_WORKERS_REQUEST = 'techData/FETCH_SUGGESTED_WORKERS_REQUEST';
export const FETCH_SUGGESTED_WORKERS_SUCCESS = 'techData/FETCH_SUGGESTED_WORKERS_SUCCESS';
export const FETCH_SUGGESTED_WORKERS_FAILURE = 'techData/FETCH_SUGGESTED_WORKERS_FAILURE';
export const INVALIDATE_SUGGESTED_WORKERS_DATA = 'techData/INVALIDATE_SUGGESTED_WORKERS_DATA';
