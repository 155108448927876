import { push as oldPush, replace as oldReplace } from 'connected-react-router';
import { isFunction } from 'lodash';
import ROUTES from 'src/routes';
import Bugsnag from 'src/utils/Bugsnag';

const makePath = (path) => {
  if (!path.includes(':')) {
    // performance optimization
    return () => path;
  }
  return function replaceParams(...args) {
    if (args.includes(null))
      Bugsnag.notifyException(new Error(`Error in makePath: null args. ${path} ${args}`));
    const result = args.reduce((link, arg) => link.replace(/:\w+/, (arg || '0').toString()), path);
    if (result.includes(':')) {
      throw new Error(`Please provide all replacement for a path: ${path} - ${result}`);
    }
    return result;
  };
};

export const push = (path, state) => {
  if (isFunction(path)) {
    return oldPush(path(), state);
  }
  return oldPush(path, state);
};

export const replace = (path, ...rest) => {
  const url = isFunction(path) ? path() : path;
  return oldReplace.call(null, url, ...rest);
};

export const pushOnClick = (bindedPush) => (path) => (event) => {
  event.preventDefault();
  return bindedPush(path);
};

export const homePath = makePath('/');
export const skuPath = makePath('/tech-support/:seoName');
export const productPath = makePath('/products/:seoName');
export const skuCatPath = makePath('/tech-support/for/:seoName');
export const landingPath = makePath('/landings/:seoName');
export const accountPath = makePath('/account');
export const accountOrderPath = makePath('/account/orders/:orderId');
export const accountOrderStatusPath = makePath('/account/orders/:orderId/:status');
export const accountOrderPublicPath = makePath('/clients/order-summary/:orderToken');
export const accountOrderStatusPublicPath = makePath('/clients/order-summary/:orderToken/:status');
export const accountProfilePath = makePath('/account/profile');
export const accountPlanPath = makePath('/account/plan');
export const accountPlanDetailsPath = makePath('/account/plan/:details');
export const accountDevicesPath = makePath('/account/devices');
export const accountScoreCardPath = makePath('/account/scorecard');
export const accountPaymentPath = makePath('/account/payment');
export const accountMyProsPath = makePath('/account/my-pros');
export const techMapPath = makePath('/account/tech-location/:id');
export const cartPath = makePath('/cart');
export const loginPath = makePath('/login');
export const signInPath = makePath('/sign');
export const createAccountPath = makePath('/create-account');
export const addSkuPath = makePath(`${ROUTES.ADD_SKU}:id`);
export const skuRecommendPath = makePath(`${ROUTES.ADD_SKU}:id/recommend`);
export const planPath = makePath('/plans/:seoName');
export const plansPath = makePath('/plans');
export const resetPasswordPath = makePath('/clients/reset-password');
export const planThankYouPath = makePath('/plans/:seoName/thank-you');
export const clientSignupPath = makePath('/clients/signup');
export const clientsRegistrationPath = makePath('/clients/register');
export const clientCheckEmailPath = makePath('/clients/check-email');
export const bookingStagePath = makePath('/cart/booking/:stage');
export const orderConfirmationPath = makePath('/clients/orders/confirmation/:id');
export const subscriptionConfirmationPath = makePath('/clients/subscription/confirmation');
export const reschedulePath = makePath('/clients/orders/reschedule/:id');
export const publicReschedulePath = makePath('/clients/order-reschedule/:orderToken');
export const meetOurTechsPath = makePath('/meet-our-techs');
export const contactUsPath = makePath('/contact-us');
export const faqPath = makePath('/faq');
export const cancellationPolicyPath = makePath('/cancellation-policy');
export const snsPath = makePath('/sns');
export const tosPath = makePath('/tos');
export const privacyPath = makePath('/privacy');
export const locationsRootPath = makePath('/us');
export const customerReviewsPath = makePath('/customer-reviews');
export const subscriptionGifConfirmationPath = makePath('/clients/subscription-gift/confirmation');
export const directBookingTechProfilePath = makePath(`${ROUTES.DIRECT_BOOKING}/:techId`);
export const directBookingTechSkusPath = makePath(`${ROUTES.DIRECT_BOOKING}/:techId/services`);
export const directBookingTechProductsPath = makePath(`${ROUTES.DIRECT_BOOKING}/:techId/products`);
export const orderReviewsPath = makePath('/rating/:id/:stars');
export const simplisafePath = makePath('/simplisafe');
export const getOrderStatusPath = makePath('/orderstatus');
export const speedTestPage = makePath('/speed');
export const cartBookingSkuPath = makePath('/cart/booking/sku');
export const setPasswordPath = makePath('/complete_order/set_password');
export const completeOrderPath = makePath('/complete_order');
export const targetPinRedemptionPath = makePath('/target');
export const prepaidPinRedemptionPath = makePath('/prepaid');
export const radioOfferPath = makePath('/radio-offer');
export const radioPath = makePath('/radio');
export const productsIframeBasePath = makePath('/products/shop'); // src/containers/ProductPage/IframeProductPage/constants.js
export const twoFactorPinPath = makePath('/cart/pin');
export const servicesPath = makePath('/services');
// field sales (fieldsales)
export const fieldSalesPath = makePath('/fieldsales/:agentType');
export const fieldSalesSkuListPath = makePath('/fieldsales/:agentType/skulist');
export const fieldSalesCustomerPath = makePath('/fieldsales/:agentType/customer');
export const fieldSalesVerifyCustomerPath = makePath('/fieldsales/:agentType/customer/verify');
export const fieldSalesQRCodePath = makePath('/fieldsales/:agentType/qrcode');
// Palo Alto Networks -- Temporary
export const paloAltoNetworksApiFlowPath = makePath(`${ROUTES.BOOKING_PALO_ALTO_NETWORKS}/:stage`);
export const paloAltoNetworksOrderConfirmationPath = makePath(
  '/clients/palo-alto-networks/confirmation/:orderId',
);
// export const accountOrderPath = makePath('/account/orders/:orderId');
export const paloAltoNetworksAccountOrderPath = makePath('/account/palo-alto-orders/:orderId');
export const paloAltoNetworksAccountOrderPublicPath = makePath(
  '/clients/palo-alto-order-summary/:orderToken',
);
export const paloAltoNetworksAccountOrderStatusPublicPath = makePath(
  '/clients/palo-alto-order-summary/:orderToken/:status',
);
export const paloAltoNetworksAccountOrderStatusPath = makePath(
  '/account/palo-alto-orders/:orderId/:status',
);
export const paloAltoReschedulePath = makePath('/clients/palo-alto-orders/reschedule/:id');
export const paloAltoPublicReschedulePath = makePath(
  '/clients/palo-alto-order-reschedule/:orderToken',
);
export const paloAltoOrderReviewsPath = makePath('/palo-alto-rating/:id');
export const homehubPath = makePath('/homehub');
export const homehubCategoryPath = makePath('/homehub/:category');

// EV
export const evCustomerInfoPath = makePath('/ev/customer-info');
export const evSkuQuestionsPath = makePath('/ev/add-sku/:skuId');
export const evSchedulingPath = makePath('/ev/cart/booking/availability');
export const evPaymentPath = makePath('/ev/cart/booking/payment');
export const evReviewPath = makePath('/ev/cart/booking/review');
export const evPortalPath = makePath('/ev/portal/:workflowToken');
export const evReschedulePath = makePath('/ev/orders/reschedule/:orderId');
export const evPublicReschedulePath = makePath('/ev/order-reschedule/:orderToken');
export const evTechQuoteConfirmationPath = makePath('/ev/tech/quote/confirmation');
