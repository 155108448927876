// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from 'src/components/HTKit/Grid';
// Logos
import samsungLogo from 'src/images/branding/samsung.svg';
import htDoorHorizontalLogo from 'src/images/ht_door_horizontal_logo.svg';
// Styles
import { homePath } from '../../../utils/paths';
import styles from './styles.scss';

const SamsungHeader = ({ header }) => {
  return (
    <Grid.FullWidth classes={styles.grid}>
      <Grid.Row classes={styles.row}>
        <Grid.Column sm={2} md={4} lg={6} classes={styles.htLogoContainer}>
          {header.get('actionableLogo') ? (
            <Link to={homePath()} className={styles.linkStyle}>
              <img src={htDoorHorizontalLogo} className={styles.htLogo} alt="HelloTech Logo" />
            </Link>
          ) : (
            <img src={htDoorHorizontalLogo} className={styles.htLogo} alt="HelloTech Logo" />
          )}
        </Grid.Column>
        <Grid.Column sm={2} md={4} lg={6} classes={styles.samsungLogoContainer}>
          {header.get('actionableLogo') ? (
            <Link to={homePath()} className={styles.linkStyle}>
              <img src={samsungLogo} className={styles.samsungLogo} alt="Samsung Logo" />
            </Link>
          ) : (
            <img src={samsungLogo} className={styles.samsungLogo} alt="Samsung Logo" />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

function mapStateToProps(state) {
  return {
    header: state.getIn(['layout', 'header']),
  };
}

SamsungHeader.propTypes = {
  header: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SamsungHeader);

/*
  SamsungHeader:
    When Samsung orders are received through API the client is sent an email to set a password
    and setup an appointment. Per our agreement with Samsung the pages have to be Samsung/HelloTech
    branded. This header is applied for those situations.

  Examples:
    <SamsungHeader />

  Properties:
    backgroundColor:
      type: string
      description: Used to set the background-color CSS property

  Last modified: -GH March 20, 2017
*/
