import React from 'react';
import { useSelector } from 'react-redux';
import { partnerCoBrandImagesAllAvenuesSelector } from 'src/selectors/partner';
// Assets && Components
import { Grid } from 'src/components/HTKit/Grid';
import htDoorHorizontalLogo from 'src/images/ht_door_horizontal_logo.svg';
// Styles
import styles from './styles.scss';

const CobrandHeader = () => {
  const cobrandedImages = useSelector(partnerCoBrandImagesAllAvenuesSelector);

  return (
    <Grid.Fluid classes={styles.grid}>
      <Grid.Row classes={styles.row}>
        <Grid.Column sm={2} md={4} lg={6} classes={styles.htLogoContainer}>
          <img src={htDoorHorizontalLogo} className={styles.htLogo} alt="HelloTech Logo" />
        </Grid.Column>
        {cobrandedImages && (
          <Grid.Column sm={2} md={4} lg={6} classes={styles.cobrandLogo}>
            {cobrandedImages.desktop && (
              <img
                alt={cobrandedImages.alt}
                className={styles.cobrandheader_logo__desktop}
                src={cobrandedImages.desktop}
              />
            )}
            {cobrandedImages.mobile && (
              <img
                alt={cobrandedImages.alt}
                className={styles.cobrandheader_logo__mobile}
                src={cobrandedImages.mobile}
              />
            )}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid.Fluid>
  );
};

export default CobrandHeader;

/*
  CobrandHeader
    - Partner + partner images (admin derived), show this header.
*/
