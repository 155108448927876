import { flatten } from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { pageLoadingError, pageLoaded } from 'src/actions/common';
import { userSelector } from 'src/selectors/user';
import availabilitySaga from './AvailabilityPage/sagas';
import addressSaga from './AddressPage/sagas';
import paymentSaga from './PaymentPage/sagas';
import summarySaga from './SummaryPage/sagas';
import { authOverride } from './utils';
import { CREATE_PASSWORDLESS_ACCOUNT } from './constants';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { userSignedUp } from '../RegistrationPage/actions';
import { updateAuth, userLoaded } from '../AppBase/actions';
import { goToStage } from 'src/containers/BookingPage/actions';
import { setLeadGeneratedCookie } from 'src/utils/cookies/leadGenerated';
import { LEAD_GEN_SOURCE_TYPES } from 'src/constants/tracking';
import {
  techMyWorkersStateJsSelector,
  techSuggestedWorkerStateJsSelector,
} from 'src/selectors/techs';
import { FETCH_STATUSES } from 'src/components/Techs/data/constants';
import {
  fetchMyWorkersRequest,
  fetchSuggestedWorkersRequest,
} from 'src/components/Techs/data/actions';

const REGISTRATION_SOURCE_PASSWORDLESS = 'passwordless';

function* fetchSuggestedworkerFlowData() {
  const myWorkerState = yield select(techMyWorkersStateJsSelector);
  const suggestedWorkersState = yield select(techSuggestedWorkerStateJsSelector);

  if (myWorkerState.fetchStatus !== FETCH_STATUSES.SUCCEEDED) {
    yield put(fetchMyWorkersRequest());
  }
  if (suggestedWorkersState.fetchStatus !== FETCH_STATUSES.SUCCEEDED) {
    yield put(fetchSuggestedWorkersRequest());
  }
}

function* pageSaga({ stage }) {
  const user = yield select(userSelector);
  if (!user && !authOverride(stage)) {
    return;
  }

  const routes = yield call(selectRoutes);
  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  if (!cart || !cart.get('breakdown')) {
    cart = null;
  }
  if (cart) {
    cart = cart.toJS();
  } else {
    const requestResult = yield call(routes.cart.find, { breakdown: true });
    if (!requestResult.err) {
      cart = requestResult.data.cart;
    } else {
      yield put(pageLoadingError('booking', requestResult));
      return;
    }
  }
  yield call(fetchSuggestedworkerFlowData);
  yield put(pageLoaded('booking', { cart }));
}

function* handleCreatePasswordlessAccount(action) {
  const { user } = action.payload;
  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.users.registration, {
    user,
    sync_tasks: true,
    source: REGISTRATION_SOURCE_PASSWORDLESS,
  });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
    return;
  }

  const { user: newUser } = response.data;
  const { authToken } = newUser;
  yield put(updateAuth({ authToken }));
  yield put(userLoaded(newUser));
  yield put(userSignedUp({ user: newUser, attributes: action.payload.user })); // Tracking event
  yield call(routes.tracking.leadGeneration, {
    email: user.email,
    source_name: LEAD_GEN_SOURCE_TYPES.registration.name,
    source_type: LEAD_GEN_SOURCE_TYPES.registration.type,
  });
  /* Set cookie */
  setLeadGeneratedCookie({ email: newUser.email });

  // For the passswodless auth feature creating an order  is the only way a user
  // can create an account, so we are ok to push them to the next step in the Booking flow.
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  yield put(goToStage(cart.get('status'), false));
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === 'booking', pageSaga);
  yield takeLatest(CREATE_PASSWORDLESS_ACCOUNT, handleCreatePasswordlessAccount);
}

export default flatten([pageFlow, availabilitySaga, addressSaga, paymentSaga, summarySaga]);
