import { formatDollarAmount } from 'src/utils/formatter/currency';
import { BOOKING_STAGES } from './constants';

export const authOverride = (stage) => {
  if (stage === 'verification') {
    return true;
  }
  return false;
};

export const totalSubscriptionCreditAdjustments = (subscriptionCreditAdjustments = []) => {
  if (Array.isArray(subscriptionCreditAdjustments) && subscriptionCreditAdjustments.length) {
    const creditAmount = subscriptionCreditAdjustments.reduce((acc, credit) => {
      return acc + credit.amount;
    }, 0);

    return {
      amount: creditAmount,
      amountFormatted: formatDollarAmount({ amount: creditAmount, truncateEvenDollars: true }),
    };
  }
  return {
    amount: 0,
    amountFormatted: '$0',
  };
};

// eslint-disable-next-line no-shadow
export const allowStage = ({ user, stage, cart, allowOverrideStage, authOverride }) => {
  /**
   * authOverride is required because the page redirects to /cart page when user doesn't exists (in pagesFlow.js)
   * We want users to be redirected to /sign or /verification step where there's login etc
   */
  if (!authOverride && (!user || !cart)) return false;

  if (
    stage === allowOverrideStage ||
    [BOOKING_STAGES.VERIFICATION, BOOKING_STAGES.SELECT_WORKER].includes(stage)
  )
    return true;
  /**
   * The values in this switch statement are all derived from cart.get('status'). Only add stages here that are
   * derived from that value.
   * */
  const status = cart.get('status');
  switch (stage) {
    case BOOKING_STAGES.ADDRESS:
      return [
        BOOKING_STAGES.ADDRESS,
        BOOKING_STAGES.AVAILABILITY,
        BOOKING_STAGES.PAYMENT,
        BOOKING_STAGES.SUMMARY,
      ].includes(status);
    case BOOKING_STAGES.AVAILABILITY:
      return [BOOKING_STAGES.AVAILABILITY, BOOKING_STAGES.PAYMENT, BOOKING_STAGES.SUMMARY].includes(
        status,
      );
    case BOOKING_STAGES.PAYMENT:
    case BOOKING_STAGES.SUMMARY:
      return [BOOKING_STAGES.PAYMENT, BOOKING_STAGES.SUMMARY].includes(status);
    default:
      throw new Error(`Unknown stage: ${stage}`);
  }
};
