import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as Yup from 'yup';
import get from 'lodash/get';

// Hooks
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import useAPI from 'src/hooks/useAPI';
import { useLeadGeneration } from 'src/utils/leadGenerated/useLeadGeneration';
// Redux Actions
import { getAPriceClickSegment } from 'src/containers/ServicePage/actions';
import { addCouponToStateForCart } from 'src/containers/CartPage/actions';

// Constants
import {
  ZIP_EMAIL_ERROR_TEXT,
  zipOnlyTestTreatment,
  zipCodeCta,
  specialOfferCouponSplitTest,
} from 'src/components/Service/ServiceZipCodeEntry/constants';
import { ON, CONTROL } from 'src/components/SplitIO/constants/base';
// Utils
import { privacyPath } from 'src/utils/paths';
import { setZipDataCookie, getZipDataCookie } from 'src/utils/cookies/zipCodeTestCookie';
import { logger } from 'src/utils/logger';
// Components & Styles
import { Link } from 'react-router-dom';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import Icon from 'HTKit/Icon';
import Form from 'src/components/HTKit/Forms/Form';
import InputFieldV2 from 'src/components/HTKit/Forms/InputFieldV2';
import {
  EmailFieldWithValidation,
  useExternalEmailCheck,
} from 'src/components/HTKit/Forms/EmailFieldWithValidation';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { Badge, BADGE_TYPES } from 'Elements/Badge';
import HT_INFO from 'src/constants/hellotech';
import HTGladlyChat from 'src/utils/Gladly/HTGladlyChat';
import CoverageNotAvailableModal from './CoverageNotAvailableModal';
import ServiceZipCodeEntrySuccess from './ServiceZipCodeEntrySuccess';
import ErrorCalloutBox from './ErrorCalloutBox';
import styles from './styles.scss';

const ZIP_VALIDATION_STATE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const ServiceZipCodeEntry = ({ splitTreatment, onGetPriceClick, className, ...rest }) => {
  const api = useAPI();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { submitLeadGenerated } = useLeadGeneration();

  // This will be some enum type (ZIP_VALIDATION_STATE.SUCCESS | ZIP_VALIDATION_STATE.ERROR | null)
  const [zipValidationState, setZipValidationState] = useState(null);
  const resetZipValidationState = () => setZipValidationState(null);
  const isZipSuccessState = zipValidationState === ZIP_VALIDATION_STATE.SUCCESS;
  const isZipErrorState = zipValidationState === ZIP_VALIDATION_STATE.ERROR;

  const openModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => setIsModalVisible(false);
  const openChat = (e) => {
    e.stopPropagation();
    HTGladlyChat.open();
  };
  const showZipOnly = [zipOnlyTestTreatment, CONTROL].includes(splitTreatment);

  /* Cookie Data */
  const zipDataCookie = getZipDataCookie() || { isBookingAllowed: true };

  /* Zip Validation */
  const verifyIsBookingAllowed = async (zipCode = '') => {
    try {
      setZipValidationState(null);
      const response = await api.locations.getTechCoverageByZip({ zip: zipCode });
      const {
        data: { treatment },
      } = response;
      if (response.err) throw new Error(response.err);
      if (!treatment) throw new Error(String(response));

      const isBookingAllowed = [ON, CONTROL].includes(treatment);
      setZipDataCookie({ ...zipDataCookie, zipCode, isBookingAllowed });
      setZipValidationState(
        isBookingAllowed ? ZIP_VALIDATION_STATE.SUCCESS : ZIP_VALIDATION_STATE.ERROR,
      );

      if (!isBookingAllowed) openModal();
      return isBookingAllowed;
    } catch (error) {
      // By default we should be accepting orders, not turning them away, so return true if error
      setZipDataCookie({ ...zipDataCookie, zipCode, isBookingAllowed: true });
      setZipValidationState(ZIP_VALIDATION_STATE.SUCCESS);
      logger('ServiceZipCodeEntry - verifyIsBookingAllowed')(error);
      return true;
    }
  };

  /* Each treatment as different tos. Handle here. */
  /**
   * Little helper to get the phone number from the HT_INFO object or the default
   * @param key
   * @returns {*}
   */
  const getPhoneOrDefault = (key) => {
    return get(
      HT_INFO,
      `phone.customers.zipcodeSkuGateSplit.${key}`,
      get(HT_INFO, `phone.customers.default.${key}`),
    );
  };

  /**
   * Display the contact info
   * @returns {JSX.Element}
   * @constructor
   */
  const ContactDisplay = () => {
    return (
      <p className="p1 paddingTop-small1 text-weight-med">
        Questions?{' '}
        <span className="plainButton underline" onClick={openChat}>
          Chat with us
        </span>{' '}
        or call{' '}
        <a
          className={cn(styles.maskLinkColor, 'underline plainButton')}
          href={getPhoneOrDefault('link')}
        >
          {getPhoneOrDefault('title')}
        </a>{' '}
        to get help 24/7
      </p>
    );
  };
  const TosDisplay = () => {
    return (
      !showZipOnly && (
        <p className="paddingTop-tiny1 caption n700">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          By continuing you agree to HelloTech's{' '}
          <Link className={cn(styles.maskLinkColor, 'underline')} to={privacyPath}>
            privacy policy
          </Link>
        </p>
      )
    );
  };

  /* Form Validation */
  const isValidZip = /(^\d{5}$)/;

  let schema = Yup.object().shape({
    zipCode: Yup.string()
      .matches(isValidZip, ZIP_EMAIL_ERROR_TEXT.ZIP)
      .max(5)
      .required(ZIP_EMAIL_ERROR_TEXT.ZIP),
  });

  if (!showZipOnly) {
    schema = Yup.object().shape({
      zipCode: Yup.string()
        .matches(isValidZip, ZIP_EMAIL_ERROR_TEXT.ZIP)
        .max(5)
        .required(ZIP_EMAIL_ERROR_TEXT.ZIP),
      email: Yup.string()
        .email(ZIP_EMAIL_ERROR_TEXT.EMAIL)
        .required(ZIP_EMAIL_ERROR_TEXT.EMAIL),
    });
  }

  const submitUserDetails = async ({ zipCode, email }) => {
    dispatch(getAPriceClickSegment({ zipCode, email, treatment: splitTreatment }));
    if (email) {
      submitLeadGenerated({ email, source: 'skuGate', page: 'ServiceZipCodeEntry' });
    }
    const isVerified = await verifyIsBookingAllowed(zipCode);

    if (isVerified && email) {
      dispatch(
        addCouponToStateForCart({
          code: specialOfferCouponSplitTest,
          withToast: true,
          successMsg: '10% off will be applied in your cart!',
        }),
      );
    }
  };
  const formik = useFormik({
    initialValues: { zipCode: '', email: '' },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit(values) {
      validateEmailWithConfig({ email: values.email });
    },
  });

  const resetZipCodeForm = () => {
    resetZipValidationState();
    formik.handleChange({
      target: { name: 'zipCode', value: '' },
    });
  };

  const {
    validateEmailWithConfig,
    emailValidationState,
    useSuggestion,
    handleOnChange,
  } = useExternalEmailCheck({
    useValidationService: !showZipOnly,
    formik,
    fieldName: 'email',
    onSuccess: () =>
      submitUserDetails({ zipCode: formik.values.zipCode, email: formik.values.email }),
  });

  /* Styles */
  const inputWrapperStyles = cn(styles.inputFieldsWrapper, 'flex', {
    alignItemsEnd: showZipOnly && !formik.errors.zipCode,
    alignItemsCenter: showZipOnly && formik.errors.zipCode,
  });

  const errorCalloutMobileTabStyles = cn('showMobileTablet width-full', {
    'marginBottom-medium': showZipOnly,
    'marginTop-small1': !showZipOnly,
  });

  /* Effects */
  useEffect(() => {
    // zipDataCookie.zipCode will return undefined unless the client has made at least 1 attempt to verify the zip. See verifyIsBookingAllowed() above
    if (zipDataCookie.zipCode) {
      setZipValidationState(
        zipDataCookie.isBookingAllowed ? ZIP_VALIDATION_STATE.SUCCESS : ZIP_VALIDATION_STATE.ERROR,
      );
    }
  }, [zipDataCookie.isBookingAllowed, zipDataCookie.zipCode]);

  if (isZipSuccessState)
    return (
      <ServiceZipCodeEntrySuccess
        className={className}
        resetZipCodeForm={resetZipCodeForm}
        onButtonClick={onGetPriceClick}
        {...rest}
      />
    );
  return (
    <>
      {!showZipOnly && (
        <Badge type={BADGE_TYPES.CONFIRMED} classes={styles.badge}>
          <div className="flex alignItemsCenter">
            <Icon name="money" />
            <div className="text-weight-med">Book today and get 10% off!</div>
          </div>
        </Badge>
      )}
      <PanelV2 mediumBorderRadius className={cn(className, styles.zipContent)}>
        <div className="paddingBottom-small">
          <p className="text-weight-med">Check local pricing</p>
          {!showZipOnly && (
            <p className="caption n700 paddingTop-tiny">
              We will email you a copy of your price quote.
            </p>
          )}
        </div>
        <Form className={styles.field_sales__form} onSubmit={formik.handleSubmit}>
          <div className={inputWrapperStyles}>
            <InputFieldV2
              name="zipCode"
              label="Zip Code"
              maxlength="5"
              type="text"
              inputWrapperClass={styles.input}
              containerClass={styles.zipInput}
              onChange={formik.handleChange}
              value={formik.values.zipCode}
              error={formik.errors.zipCode}
            />
            {!showZipOnly && (
              <EmailFieldWithValidation
                name="email"
                label="Email Address"
                type="text"
                inputWrapperClass={styles.input}
                containerClass="width-full"
                value={formik.values.email}
                error={!showZipOnly && formik.errors.email}
                onChange={handleOnChange}
                softWarning={emailValidationState?.did_you_mean}
                useSuggestion={useSuggestion}
              />
            )}
            <ErrorCalloutBox
              zip={zipDataCookie.zipCode}
              className={errorCalloutMobileTabStyles}
              visible={isZipErrorState}
            />
            {showZipOnly && (
              <Button
                className={formik.errors.zipCode ? 'marginTop-tiny1' : ''}
                theme={THEMES.V2PRIMARY}
                type="submit"
              >
                {zipCodeCta}
              </Button>
            )}
          </div>
          <ErrorCalloutBox
            zip={zipDataCookie.zipCode}
            className="showDesktop marginTop-small"
            visible={isZipErrorState}
          />
          {!showZipOnly && (
            <Button className="marginTop-small1" theme={THEMES.V2PRIMARY} type="submit">
              {zipCodeCta}
            </Button>
          )}
          <ContactDisplay />
          <TosDisplay />
        </Form>
        <CoverageNotAvailableModal visible={isModalVisible} handleClose={closeModal} />
      </PanelV2>
    </>
  );
};

ServiceZipCodeEntry.propTypes = {
  splitTreatment: PropTypes.string,
  onGetPriceClick: PropTypes.func, // Callback function defined in the parent
  className: PropTypes.string,
};
export default ServiceZipCodeEntry;

/*

  Notes:
  testA is zip only treatment
  testB (used in ServicePage.js) is zip + email treatment
*/
