import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { ADD_WORKER_FROM_DEEP_LINK } from 'src/components/Techs/data/constants';
import { PAGE_NAME, MEMBERSHIP_ADDITION_TOAST_DISPLAYED } from './constants';

const initialState = fromJS({
  publicPage: false,
  formattedRescheduleUrl: '',
  membershipAdditionToastDisplayed: false,
  favoriteTechDeepLinkProcessed: false,
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED: {
      if (action.page === PAGE_NAME) {
        const { publicPage, formattedRescheduleUrl } = action;
        return state.merge({ publicPage, formattedRescheduleUrl });
      }
      return state;
    }
    case MEMBERSHIP_ADDITION_TOAST_DISPLAYED: {
      const { payload } = action;
      return state.set('membershipAdditionToastDisplayed', payload);
    }
    case ADD_WORKER_FROM_DEEP_LINK: {
      // Order page transitions from /:orderId to /:orderId/:orderStatus triggers
      // multiple LOAD_PAGE actions. Track when deep link is first processed
      // to prevent duplicate handling during route changes.
      return state.set('favoriteTechDeepLinkProcessed', true);
    }
    default:
      return state;
  }
}

export default pageReducer;
