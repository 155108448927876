import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  useGladlyKnowledgeBase,
  DOM_IDS,
  KNOWLEDGE_BASE_TYPES,
} from 'src/components/Gladly/useGladlyKnowledgeBase';
import styles from './styles.scss';

export const GladlyKnowledgeBase = ({ className, type = KNOWLEDGE_BASE_TYPES.CUSTOMER }) => {
  useGladlyKnowledgeBase(type);
  return <div id={DOM_IDS.CONTAINER} className={cn(styles.gladlyContainer, className)} />;
};

GladlyKnowledgeBase.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(KNOWLEDGE_BASE_TYPES)),
};
