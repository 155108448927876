/* eslint-disable prefer-const */
import each from 'lodash/each';
import request, { toQueryString, postJson, putJson, deleteJson } from './request';

const protocol =
  process.env.PROTOCOL || (process.env.NODE_ENV === 'development' ? 'http' : 'https');
let base = `${protocol}://${process.env.API_HOST || 'api.hellotech.com'}`;
let dispatchBase = `${protocol}://${process.env.DISPATCH_HOST || 'core.hellotech.com/dispatch'}`;

if (!process.browser && process.env.IS_DOCKER) {
  base = `${protocol}://${process.env.DOCKER_API_HOST || 'api.hellotech.com'}:${process.env
    .DOCKER_API_PORT || '80'}`;
  dispatchBase = `${protocol}://${process.env.DOCKER_DISPATCH_HOST ||
    'core.hellotech.com/dispatch'}:${process.env.DOCKER_DISPATCH_PORT || '80'}`;
}

export const BASE_LOCATIONS = {
  DEFAULT: base,
  DISPATCH: dispatchBase,
};

function makePath(path, location = BASE_LOCATIONS.DEFAULT, { replacements, params }) {
  let newPath = path;
  if (replacements) {
    if (!(typeof replacements === 'object')) {
      throw new Error(
        `Replacements need to be object, ${typeof replacements} provided for ${newPath}`,
      );
    }
    each(replacements, (value, name) => {
      if (newPath.includes(`:${name}`)) {
        newPath = newPath.replace(`:${name}`, value);
      } else {
        throw new Error(`Can't find :${name} in ${newPath}`);
      }
    });
    if (newPath.includes(':')) {
      throw new Error(`Not all replacements were provided: ${newPath}`);
    }
  }

  let query = '';
  if (params) {
    query = `?${toQueryString(params)}`;
  }
  return `${location}${newPath}${query}`;
}

function resolvePathArgs(path, args) {
  const shouldHaveReplacements = path.includes(':');
  let replacements;
  let data;
  let options;

  if (shouldHaveReplacements) {
    replacements = args[0];
    data = args[1];
    options = args[2] || {};
    if (!replacements) {
      throw new Error(`Please provide attributes for path: ${path}`);
    }
  } else {
    data = args[0];
    options = args[1] || {};
  }
  return { shouldHaveReplacements, data, options, replacements };
}

function makeGetPath(path, location, args) {
  const { replacements, data: params, shouldHaveReplacements } = resolvePathArgs(path, args);
  return makePath(path, location, { replacements: shouldHaveReplacements && replacements, params });
}

export default function(auth) {
  return {
    get(path, location) {
      const curry = (...args) => {
        const { options } = resolvePathArgs(path, args);
        return request(makeGetPath(path, location, args), options, auth);
      };
      curry.getPath = (args) => makeGetPath(path, location, args);
      return curry;
    },

    post(path, location) {
      return (...args) => {
        const { replacements, data, options, shouldHaveReplacements } = resolvePathArgs(path, args);
        const result = makePath(path, location, {
          replacements: shouldHaveReplacements && replacements,
        });
        return postJson(result, data, options, auth);
      };
    },

    put(path, location) {
      return (...args) => {
        const { replacements, data, options, shouldHaveReplacements } = resolvePathArgs(path, args);
        const result = makePath(path, location, {
          replacements: shouldHaveReplacements && replacements,
        });
        return putJson(result, data, options, auth);
      };
    },

    // delete is a reserved word
    destroy(path, location) {
      return (...args) => {
        const { replacements, data, options, shouldHaveReplacements } = resolvePathArgs(path, args);
        const result = makePath(path, location, {
          replacements: shouldHaveReplacements && replacements,
        });
        return deleteJson(result, data, options, auth);
      };
    },
  };
}
