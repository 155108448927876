import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getInitials } from 'src/utils/text';
import useWithStyles from 'src/hooks/useWithStyles';
import styles from './styles.scss';

const VIEW_TYPES = { INITIALS: 'initials', IMAGE: 'image' };

const getLabel = (name, imageAlt) => imageAlt || `Your HelloTech Technician is ${name}`;

const shouldRenderAvatar = ({ image, name, imageLoadError }) => {
  const hasValidInput = image || name; // At least one of image or name is provided
  const canRenderImageOrFallback = !imageLoadError || name; // Either the image loads successfully, or there's a name to fall back to
  return hasValidInput && canRenderImageOrFallback;
};

/**
 * Usages:
 *   default: <Avatar name='John J. Doe' image='some/image' />
 *   w/modifier: <Avatar image='/some/path' small />
 *   w/className: <Avatar image='image/path' name='John Doe' className={`${styles.foo} ${styles.whatever}`} />
 *
 * @param props
 * @returns {null| jsx}
 * @constructor
 */
const Avatar = (props) => {
  const [viewType, setViewType] = useState(props.image ? VIEW_TYPES.IMAGE : VIEW_TYPES.INITIALS);
  const [imageLoadError, setImageLoadError] = useState(false);

  const { image, name, imageAlt, className } = props;
  const avatarClass = cn(styles.avatarcontainer, useWithStyles(props, styles), {
    [className]: className,
  });

  useEffect(() => {
    if (image && imageLoadError) {
      setViewType(VIEW_TYPES.INITIALS);
    }
  }, [image, imageLoadError]);

  if (!shouldRenderAvatar({ image, name, imageLoadError })) return null;
  return (
    <div aria-label={getLabel(name)} className={avatarClass}>
      {viewType === VIEW_TYPES.INITIALS ? (
        getInitials(name)
      ) : (
        <img
          className={styles.avatarImage}
          alt={getLabel(name, imageAlt)}
          src={image}
          onError={() => setImageLoadError(true)}
        />
      )}
    </div>
  );
};

Avatar.defaultProps = {
  className: '',
  name: '',
  image: '',
  imageAlt: '',
};

Avatar.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(Avatar);
