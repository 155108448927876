import { MIN_RATING_TO_OFFER_PREVIOUS_WORKER } from 'src/constants/cart';

/**
 * Service to manage worker eligibility and selection logic for booking flows.
 * Determines when to show worker selection and which workers are eligible
 * based on customer history and preferences.
 *
 * @param {{cart: Cart, suggestedWorkers: FavoriteWorker[], myWorkers: WorkerListCategories, isDirectBooking: boolean}} params
 */
export const createWorkerEligibilityService = ({
  cart,
  suggestedWorkers = [],
  myWorkers = {},
  isDirectBooking,
}) => {
  const favorites = myWorkers?.favorites || [];
  const matchingFavorites = favorites.filter((favorite) =>
    suggestedWorkers.some((tech) => tech.id === favorite.id),
  );

  const hasMatchingFavorites = matchingFavorites.length > 0;
  const hassuggestedWorkers = suggestedWorkers?.length > 0;

  /** Determines whether to show the worker selection step in the booking flow */
  const shouldShowWorkerSelection = () => {
    if (!cart || isDirectBooking) return false;

    // Rebooking flow
    if (cart.rebookingOrder) {
      const hasHighRating = cart.rebookingOrder.rating >= MIN_RATING_TO_OFFER_PREVIOUS_WORKER;
      if (hasHighRating) return true;
      return hasMatchingFavorites;
    }

    // Normal flow - show if they have favorites that match suggested techs
    return hasMatchingFavorites;
  };

  /**
   * Retrieves available workers for selection, including matching favorites
   * and the previous worker if applicable.
   */
  const getAvailableWorkers = () => {
    if (!cart)
      return {
        hassuggestedWorkers: false,
        suggestedWorkers: [],
        hasMatchingFavorites: false,
        matchingFavorites: [],
        previousTech: null,
      };
    return {
      hassuggestedWorkers,
      suggestedWorkers,
      hasMatchingFavorites,
      matchingFavorites,
      previousTech: cart?.rebookingOrder?.tech,
    };
  };

  return {
    shouldShowWorkerSelection,
    getAvailableWorkers,
  };
};

/** @typedef {import('src/types/cart').Cart} Cart */
/** @typedef {import('src/types/techs').FavoriteWorker} FavoriteWorker */
/** @typedef {import('src/types/techs').WorkerListCategories} WorkerListCategories */
