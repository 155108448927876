import get from 'lodash/get';
import { skuMeta } from 'src/utils/jsonLd/index';
import { formatPrice } from 'src/utils/formatter/currency';
import { displayTimestamp } from 'src/utils/date';
import { getAnnualSavings } from 'src/components/Plans/Pricing/utils';
import { UPSELL_PLAN_NAME } from 'src/constants/plan';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';

export const skuPrices = (sku, cart) => {
  if (!sku || !cart) {
    return { price: '', memberPrice: '', priceDifference: '' };
  }
  const price =
    cart.remote || !cart.items.size || !sku.remoteSavings
      ? sku.startsAtPrice || ''
      : formatPrice(sku.basePrice + sku.remoteSavings);
  const memberPrice =
    sku.subscriptionPrices && sku.subscriptionPrices.cheapestPrice != null
      ? formatPrice(sku.subscriptionPrices.cheapestPrice)
      : null;
  const rawPrice = price.replace(/[^0-9.]/g, '');
  const rawMemberPrice = memberPrice != null ? memberPrice.replace(/[^0-9.]/g, '') : null;
  const rawPriceDifference = rawMemberPrice && rawPrice - rawMemberPrice;
  return {
    price,
    memberPrice,
    priceDifference:
      (rawMemberPrice != null &&
        rawPriceDifference > 0 &&
        formatPrice(rawPriceDifference, false)) ||
      null,
  };
};

export const adjustSku = (
  {
    isMobile,
    showPlanModal,
    cart,
    user,
    reviews,
    plan,
    plansInfo,
    mobileButtonCta = 'Book Now',
    showZipCodeTest,
  },
  sku,
) => {
  if (!sku) return sku;

  const prices = skuPrices(sku, cart);
  const isHourly = BREAKDOWN_PRICE_TYPES.hourly === sku.priceType;
  const showUpsell =
    (!user || !user.account.hasSubscription) &&
    sku.includedInUpsellPlan &&
    !!prices.priceDifference &&
    !isHourly;
  const showMemberPrice =
    !!(cart.plan || (user && user.account.hasSubscription)) && prices.memberPrice;
  const upsellSelected = !!cart.plan && !isHourly;
  const ctaText = showMemberPrice && showUpsell ? 'Join & Book Now' : mobileButtonCta;
  const annualSavings = getAnnualSavings(plansInfo);
  const planName = (plansInfo.monthly && plansInfo.monthly.name) || UPSELL_PLAN_NAME;

  return {
    ...sku,
    extra: {
      bannerText: `${planName} members save up to ${prices.priceDifference} on this service.`,
      showOptions: !!sku.skuBullets.length,
      showUpsellBanner: showUpsell,
      showFaq: !!sku.faq.length,
      showPlanModal,
      showUpsell,
      showMemberPrice,
      upsellSelected,
      showStickyBookNow: isMobile,
      reviewsScrollOffset: isMobile ? 100 : 130,
      image: isMobile ? sku.heroMobileImageUrl : sku.heroImageUrl,
      rating: sku.ratingAttributes && sku.ratingAttributes.rating > 0 && sku.ratingAttributes,
      prices,
      ctaText,
      showZipCodeTest,
      meta: {
        script: skuMeta(sku),
      },
      reviews: (reviews[sku.id] || []).map((r) => ({ ...r, date: displayTimestamp(r.timestamp) })),
      plan,
      plansInfo,
      annualSavings,
      selectedPlanId: get(cart, 'plan.id', null),
      user,
      priceType: sku.priceType,
    },
  };
};
