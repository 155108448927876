import { checkIsPrerenderUA, checkIsBotUA } from 'src/utils/seo';
// import { isDevelopment, isStaging, isProduction } from 'src/utils/env';
import { chatShown } from 'src/actions/chat';
import { logger } from 'src/utils/logger';
import { noop } from 'src/utils/event';
import { immutableToJS } from '../helpers';

export const GLADLY_CONFIG = {
  ENVIRONMENTS: {
    PRODUCTION: 'PROD',
    STAGING: 'STAGING',
  },
  APP_ID: 'hellotech.com',
  getScriptLoader: (environment) =>
    `!function(c,n,r,t){if(!c[r]){var i,d,p=[];d="PROD"!==t&&t?"STAGING"===t?"https://cdn.gladly.qa/gladly/chat-sdk/widget.js":t:"https://cdn.gladly.com/chat-sdk/widget.js",c[r]={init:function(){i=arguments;var e={then:function(t){return p.push({type:"t",next:t}),e},catch:function(t){return p.push({type:"c",next:t}),e}};return e}},c.__onHelpAppHostReady__=function(t){if(delete c.__onHelpAppHostReady__,(c[r]=t).loaderCdn=d,i)for(var e=t.init.apply(t,i),n=0;n<p.length;n++){var a=p[n];e="t"===a.type?e.then(a.next):e.catch(a.next)}},function(){try{var t=n.getElementsByTagName("script")[0],e=n.createElement("script");e.async=!0,e.src=d+"?q="+(new Date).getTime(),t.parentNode.insertBefore(e,t)}catch(t){}}()}}(window,document,'Gladly','${environment}')`,
  DISABLED_PATHS: {
    EV_TECH: '/ev/tech',
  },
};

export default class HTGladlyChat {
  static dispatch = () => {};

  static shouldDisableChat() {
    if (typeof window === 'undefined') {
      return true;
    }

    const isPrerenderUA = checkIsPrerenderUA();
    const isBotUA = checkIsBotUA();
    const isEVTechQuote = window.location.pathname.includes(GLADLY_CONFIG.DISABLED_PATHS.EV_TECH);
    return isPrerenderUA || isBotUA || isEVTechQuote;
  }

  static getEnvironment = () => {
    return GLADLY_CONFIG.ENVIRONMENTS.PRODUCTION; // TODO: Remove once Staging sandbox is ready
    // if (isProduction()) {
    //   return GLADLY_CONFIG.ENVIRONMENTS.PRODUCTION;
    // }
    // if (isStaging() || isDevelopment()) {
    //   // return GLADLY_CONFIG.ENVIRONMENTS.STAGING;
    //   return GLADLY_CONFIG.ENVIRONMENTS.PRODUCTION;
    // }
    // // Fallback to staging for any other environment
    // return GLADLY_CONFIG.ENVIRONMENTS.STAGING;
  };

  static initializeAndStart = ({ dispatch, user = null, onChatReady = noop }) => {
    const shouldSkipInitialization =
      typeof window === 'undefined' || window.Gladly || this.shouldDisableChat();

    if (shouldSkipInitialization) {
      return;
    }

    this.dispatch = dispatch;

    // Add the Gladly loader script
    const script = document.createElement('script');
    const environment = this.getEnvironment();

    script.textContent = GLADLY_CONFIG.getScriptLoader(environment);
    document.head.appendChild(script);

    // Initialize Gladly once the script is loaded
    const initializeGladly = () => {
      if (window.Gladly) {
        window.Gladly.init({
          appId: GLADLY_CONFIG.APP_ID,
          autoShowButton: true,
        })
          .then(() => {
            // Set user info if a user exists on app mount
            if (user) {
              this.identify(user);
            }

            // Run the callback
            onChatReady();
          })
          .catch((error) => {
            logger('gladly/init')(error);
          });
      }
    };

    // Start checking for Gladly
    initializeGladly();
  };

  static identify = async (user) => {
    if (this.shouldDisableChat() || !window.Gladly || !user) {
      return;
    }

    try {
      // Extract user information
      const { email, firstName, lastName } = immutableToJS(user);
      const name = `${firstName} ${lastName}`.trim();

      /** @type {GladlyUser} */
      const existingUser = window.Gladly.getUser();

      if (existingUser) {
        // If user is different, logout first
        if (existingUser.identity !== email) {
          await window.Gladly.clearUser().catch((error) => {
            logger('gladly/identify/clear-user-error')(error);
          });
        } else {
          // Same user, no need to re-identify
          return;
        }
      }

      window.Gladly.setUser({
        name,
        email,
      });
    } catch (error) {
      logger('gladly/identify/user-data-error')(error);
    }
  };

  static logout = () => {
    if (this.shouldDisableChat() || !window.Gladly) {
      return;
    }

    window.Gladly.clearUser().catch((error) => {
      logger('gladly/logout/api-error')(error);
    });
  };

  static open = (callback = () => {}) => {
    if (this.shouldDisableChat() || !window.Gladly) {
      return;
    }

    window.Gladly.show();
    HTGladlyChat.dispatch(chatShown(true));
    callback();
  };

  static close = () => {
    if (this.shouldDisableChat() || !window.Gladly) {
      return;
    }

    window.Gladly.close();
  };
}

// ######
// TYPES
// ######

/**
 * @typedef {Object} GladlyUser
 * @property {string} name - The user's name
 * @property {'ANONYMOUS'|'AUTHENTICATED'|'SELF_IDENTIFIED'} type - The type of user
 * @property {'EMAIL'|'MOBILE_PHONE_NUMBER'} identityType - The type of identity used
 * @property {string} identity - The identity value (email or phone number)
 * @property {boolean} hasOpenConversation - Whether the user has an open conversation
 */
