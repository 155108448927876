/* eslint-disable import/no-cycle */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// HOC
import withLayout from 'src/hoc/withLayout';
// Components
import { KNOWLEDGE_BASE_TYPES, GladlyKnowledgeBaseContainer } from 'src/components/Gladly';
import { Compliance } from './Compliance';
// Constants
import { DEFAULT_LAYOUT } from 'src/constants/common';
// eslint-disable-next-line import/order
import { GLADLY_CONTACT_FORM_LINK } from './constants';

const FAQ = (props) => {
  const pageTitle = props.title || 'FAQs';
  return (
    <>
      {!props.hideTitle && <Helmet title={pageTitle} />}
      <Compliance
        contactFormLink={GLADLY_CONTACT_FORM_LINK}
        className="paddingTop-large2 paddingBottom-small1"
      />
      <GladlyKnowledgeBaseContainer
        className="paddingBottom-large2"
        type={KNOWLEDGE_BASE_TYPES.CUSTOMER}
      />
    </>
  );
};

FAQ.propTypes = {
  src: PropTypes.string,
  hideTitle: PropTypes.bool,
  title: PropTypes.string,
  callerPage: PropTypes.string,
  iframeId: PropTypes.string,
  containerId: PropTypes.string,
};

export const FAQwithoutLayout = React.memo(FAQ);

export default React.memo(withLayout(DEFAULT_LAYOUT)(FAQ));
