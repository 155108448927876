import { DEFAULT_LAYOUT_NO_FOOTER } from 'src/constants/common';
import { targetPinRedemptionPath, prepaidPinRedemptionPath } from 'src/utils/paths';

export const PAGE_NAME = 'cart';
export const REMOVE_ITEM = 'cartPage/REMOVE_ITEM';
export const START_BOOKING = 'cartPage/START_BOOKING';
export const ITEM_ADDED = 'cartPage/ITEM_ADDED';
export const ITEM_EDITED = 'cartPage/ITEM_EDITED';
export const CLEAR_ITEM_MESSAGES = 'cartPage/CLEAR_ITEM_MESSAGES';
export const SHOW_MODAL_MULTIPLE_AUTO_APPLY_COUPONS =
  'cartPage/SHOW_MODAL_MULTIPLE_AUTO_APPLY_COUPONS';
export const DISMISS_MODAL_MULTIPLE_AUTO_APPLY_COUPONS =
  'cartPage/DISMISS_MODAL_MULTIPLE_AUTO_APPLY_COUPONS';
export const SET_RELATED_SKUS = 'cartPage/SET_RELATED_SKUS';
export const LOAD_RELATED_SKUS = 'cartPage/LOAD_RELATED_SKUS';
export const TOGGLE_ADD_ANOTHER_SERVICE = 'cartPage/TOGGLE_ADD_ANOTHER_SERVICE';
export const REMOVE_PLAN = 'cartPage/REMOVE_PLAN';
export const LOAD_PLAN_DETAILS = 'cartPage/LOAD_PLAN_DETAILS';
export const LOAD_ESTIMATED_CART = 'cartPage/LOAD_ESTIMATED_CART';
export const ADD_PLAN_AND_START_BOOKING = 'cartPage/ADD_PLAN_AND_START_BOOKING';
export const SHOW_UPSELL_CART_VERSION = 'cartPage/SHOW_UPSELL_CART_VERSION';
export const TOGGLE_REMOVE_PLAN_MODAL = 'cartPage/TOGGLE_REMOVE_PLAN_MODAL';
export const TOGGLE_PLAN_HOLDER_MODAL = 'cartPage/TOGGLE_PLAN_HOLDER_MODAL';
export const VIEW_CART_SEGMENT_DISPATCH = 'cartPage/VIEW_CART_SEGMENT_DISPATCH';
export const API_ITEM_UPDATED = 'cartPage/API_ITEM_UPDATED';
export const UPDATE_QTY = 'cartPage/UPDATE_QTY';
export const PRODUCT_INSTALLATION_SEGMENT = 'cartPage/PRODUCT_INSTALLATION_SEGMENT';
export const ADD_COUPON_TO_CART = 'cartPage/ADD_COUPON_TO_CART';
export const ADD_CART_BY_TOKEN_TO_CART = 'cartPage/ADD_CART_BY_TOKEN_TO_CART';
export const ADD_CART_BY_TOKEN_TO_CART_SEGMENT = 'cartPage/ADD_CART_BY_TOKEN_TO_CART_SEGMENT';
export const BUTTON_CTAS = {
  PRODUCTS: 'Shop Products',
  SERVICES: 'Shop Services',
  KEEP_SHOPPING: 'Keep Shopping',
  FIELD_SALES_CTA: 'Add Another Item',
};
export const ADD_ANOTHER_PIN_REDIRECT = {
  target: targetPinRedemptionPath,
  qvc: prepaidPinRedemptionPath,
};

export const CART_LAYOUT = {
  ...DEFAULT_LAYOUT_NO_FOOTER,
  main: { withNavy050BG: true, withNavy050BGMobile: true },
};

export const BREAKDOWN_PRICE_TYPES = {
  hourly: 'hourly',
  fixed: 'fixed',
};

/**
 * Fragment of an error message returned from BE when trying to add a plan for
 * a user that already has a plan
 */
export const ALREADY_HAS_PLAN_ERROR_FRAGMENTS = {
  STAGING: 'already subscribed',
  PRODUCTION: 'already a plan holder',
};

/** Default message to show in toast when service/plan is removed */
export const DEFAULT_ITEM_REMOVAL_TOAST_MESSAGE = 'Removed from cart.';

export const DEEP_LINK_QUERY_PARAMS = {
  ADD_PLAN_ID: 'add_plan_id',
  ADD_COUPON_ID: 'add_coupon_id',
  REBOOK_ORDER_ID: 'rebook_order_id',
};
