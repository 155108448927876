import { useEffect } from 'react';
import { logger } from 'src/utils/logger';

// ##################
//   CONFIG & UTILS
// ##################

/** Internal script element IDs we use to track and manage our injected scripts */
export const SCRIPT_IDS = {
  CONFIG: 'gladly-knowledge-base-config',
  KNOWLEDGE_BASE: 'gladly-knowledge-base-script',
};

/**
 * Container IDs used by Gladly's Help Center scripts.
 * The CONTAINER ID is required and must match the 'selector' config value.
 * Gladly's script will find this element and use it as the mount point
 * for injecting the Help Center content.
 */
export const DOM_IDS = {
  CONTAINER: 'gladly-help-center',
};

const GLADLY_ENDPOINTS = {
  PRODUCTION: {
    CUSTOMER: {
      API: 'https://hellotech.us-1.gladly.com',
      CDN: 'https://cdn.gladly.com',
      ORG_ID: 'RRfjS_OhTFWfR2m7e-Wrfg',
      BRAND_ID: 'hellotech.com-en-us',
    },
    WORKER: {
      API: 'https://hellotech.us-1.gladly.com',
      CDN: 'https://cdn.gladly.com',
      ORG_ID: 'RRfjS_OhTFWfR2m7e-Wrfg',
      BRAND_ID: 'ftYUWESXReuU7QJeKb3F2g',
    },
  },
};

export const KNOWLEDGE_BASE_TYPES = {
  CUSTOMER: 'CUSTOMER',
  WORKER: 'WORKER',
};

/**
 * Creates a script ID for Gladly scripts by combining a base ID with a type suffix
 * @param {Object} params
 * @param {string} params.base - The base script ID (e.g., 'gladly-knowledge-base-config')
 * @param {'CUSTOMER' | 'WORKER'} params.type - The type of knowledge base (e.g., 'CUSTOMER' or 'WORKER')
 */
export const createGladlyScriptId = ({ base = '', type = '' } = {}) => {
  if (!base) return '';
  if (!type) return base;
  return `${base}-${type.toLowerCase()}`;
};

// #########
//   HOOK
// #########

/**
 * Dynamically injects/removes Gladly Help Center scripts and config.
 * @param {'CUSTOMER' | 'WORKER'} type - Either 'CUSTOMER' or 'WORKER' to determine which knowledge base to load
 */
export const useGladlyKnowledgeBase = (type = KNOWLEDGE_BASE_TYPES.CUSTOMER) => {
  useEffect(() => {
    const configScriptId = createGladlyScriptId({ base: SCRIPT_IDS.CONFIG, type });
    const kbScriptId = createGladlyScriptId({ base: SCRIPT_IDS.KNOWLEDGE_BASE, type });

    const isAlreadyInitialized = document.getElementById(configScriptId);

    /**
     * Removes Gladly configuration from the window object to ensure proper reinitialization.
     * The Gladly Help Center uses these flags to determine if it needs to load its content.
     * Without clearing them, subsequent page visits may not properly reload the knowledge base.
     */
    const resetGladlyState = () => {
      delete window.gladlyHCLoaded;
      delete window.gladlyHCConfig;
    };

    const cleanup = () => {
      // Remove scripts
      [configScriptId, kbScriptId].forEach((id) => {
        const script = document.getElementById(id);
        if (script) {
          script.remove();
        }
      });

      // Reset Gladly state
      const activeConfigScript = document.getElementById(configScriptId);
      const hasExistingGladlyState = window.gladlyHCConfig || window.gladlyHCLoaded;

      if (!activeConfigScript && hasExistingGladlyState) {
        resetGladlyState();
      }
    };

    if (!isAlreadyInitialized) {
      const endpointConfig = GLADLY_ENDPOINTS.PRODUCTION[type];

      // Create and inject config script
      const configScript = document.createElement('script');
      configScript.id = configScriptId;
      configScript.textContent = `
        window.gladlyHCConfig = {
          api: '${endpointConfig.API}',
          orgId: '${endpointConfig.ORG_ID}',
          brandId: '${endpointConfig.BRAND_ID}',
          cdn: '${endpointConfig.CDN}',
          selector: '#${DOM_IDS.CONTAINER}'
        };
      `;
      document.head.appendChild(configScript);

      // Create and inject knowledge base script
      const knowledgeBaseScript = document.createElement('script');
      knowledgeBaseScript.id = kbScriptId;
      knowledgeBaseScript.type = 'text/javascript';
      knowledgeBaseScript.async = true;
      knowledgeBaseScript.src = `${endpointConfig.CDN}/help-center/hcl.js`;

      const loadTimeout = setTimeout(() => {
        logger('useGladlyKnowledgeBase')(`Timeout loading script: ${kbScriptId}`);
      }, 10000);
      knowledgeBaseScript.onload = () => clearTimeout(loadTimeout);
      knowledgeBaseScript.onerror = (e) => {
        logger('useGladlyKnowledgeBase')(
          `Failed to load knowledge base script: ${kbScriptId} - Error: ${JSON.stringify(e)}`,
        );
      };
      document.head.appendChild(knowledgeBaseScript);
    }

    return cleanup;
  }, [type]);
};
