import {
  LOAD_PROFILE_DATA,
  FETCH_MY_WORKERS_REQUEST,
  FETCH_MY_WORKERS_SUCCESS,
  FETCH_MY_WORKERS_FAILURE,
  INVALIDATE_MY_WORKERS_DATA,
  ADD_TO_FAVORITES_REQUEST,
  REMOVE_FROM_FAVORITES_REQUEST,
  ADD_WORKER_FROM_DEEP_LINK,
  FETCH_BLOCK_REASONS_REQUEST,
  FETCH_BLOCK_REASONS_SUCCESS,
  BLOCK_WORKER_REQUEST,
  UNBLOCK_WORKER_REQUEST,
  FETCH_SUGGESTED_WORKERS_REQUEST,
  FETCH_SUGGESTED_WORKERS_SUCCESS,
  FETCH_SUGGESTED_WORKERS_FAILURE,
  INVALIDATE_SUGGESTED_WORKERS_DATA,
} from './constants';

export const loadProfileData = ({ techId, data }) => ({
  type: LOAD_PROFILE_DATA,
  techId,
  data,
});

export const fetchMyWorkersRequest = () => ({
  type: FETCH_MY_WORKERS_REQUEST,
});

export const fetchMyWorkersSuccess = ({ workers }) => ({
  type: FETCH_MY_WORKERS_SUCCESS,
  payload: { workers },
});

export const fetchMyWorkersFailure = () => ({
  type: FETCH_MY_WORKERS_FAILURE,
});

export const invalidateMyWorkersData = () => ({
  type: INVALIDATE_MY_WORKERS_DATA,
});

export const addToFavoritesRequest = ({ id, successMsg, withToast, orderId }) => ({
  type: ADD_TO_FAVORITES_REQUEST,
  payload: { id, successMsg, withToast, orderId },
});

export const removeFromFavoritesRequest = ({ id, successMsg, withToast }) => ({
  type: REMOVE_FROM_FAVORITES_REQUEST,
  payload: { id, successMsg, withToast },
});

export const addWorkerFromDeepLink = ({ id, onSuccess }) => ({
  type: ADD_WORKER_FROM_DEEP_LINK,
  payload: { id, onSuccess },
});

export const fetchBlockReasonsRequest = () => ({
  type: FETCH_BLOCK_REASONS_REQUEST,
});

export const fetchBlockReasonsSuccess = ({ blockReasons }) => ({
  type: FETCH_BLOCK_REASONS_SUCCESS,
  payload: { blockReasons },
});

export const blockWorkerRequest = ({
  id,
  blockReasonId,
  blockReasonText,
  successMsg,
  withToast,
}) => ({
  type: BLOCK_WORKER_REQUEST,
  payload: { id, blockReasonId, blockReasonText, successMsg, withToast },
});

export const unblockWorkerRequest = ({ id, successMsg, withToast }) => ({
  type: UNBLOCK_WORKER_REQUEST,
  payload: { id, successMsg, withToast },
});

export const fetchSuggestedWorkersRequest = () => ({
  type: FETCH_SUGGESTED_WORKERS_REQUEST,
});

export const fetchSuggestedWorkersSuccess = ({ workers }) => ({
  type: FETCH_SUGGESTED_WORKERS_SUCCESS,
  payload: { workers },
});

export const fetchSuggestedWorkersFailure = () => ({
  type: FETCH_SUGGESTED_WORKERS_FAILURE,
});

export const invalidateSuggestedWorkersData = () => ({
  type: INVALIDATE_SUGGESTED_WORKERS_DATA,
});
