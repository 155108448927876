import { connect } from 'react-redux';
import componentSelector from 'src/selectors/component';
import { toggleDropdown, setDropdownState } from './actions';
import ChatCallDropdown from './ChatCallDropdown';

const mapStateToProps = (state) => {
  const chatCallDropdown = componentSelector('chatCallDropdown')(state);
  const showDropdown = chatCallDropdown.get('show');

  return {
    chat: state.get('chat'),
    showDropdown,
  };
};

export default connect(mapStateToProps, { toggleDropdown, setDropdownState })(ChatCallDropdown);
