import { SELECT_WORKER_STAGE_SUBMITTED, SELECT_PREFERRED_WORKER } from './constants';

export const selectWorkerStageSubmitted = () => ({
  type: SELECT_WORKER_STAGE_SUBMITTED,
});

export const selectPreferredWorker = ({ id } = {}) => ({
  type: SELECT_PREFERRED_WORKER,
  payload: { id },
});
