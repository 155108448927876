import React from 'react';
// Assets && Components
import { Grid } from 'src/components/HTKit/Grid';
import okyoLogo from 'src/images/branding/Okyo-logo.svg';
import htDoorHorizontalLogo from 'src/images/ht_door_horizontal_logo.svg';
// Styles
import styles from './styles.scss';

const PaloAltoOkyoHeader = () => {
  return (
    <Grid.FullWidth classes={styles.grid}>
      <Grid.Row classes={styles.row}>
        <Grid.Column sm={2} md={4} lg={6} classes={styles.okyoLogoContainer}>
          <img src={okyoLogo} className={styles.okyoLogo} alt="Okyo Logo" />
        </Grid.Column>
        <Grid.Column sm={2} md={4} lg={6}>
          <img src={htDoorHorizontalLogo} className={styles.htLogo} alt="HelloTech Logo" />
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

export default PaloAltoOkyoHeader;
