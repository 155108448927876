import get from 'lodash/get';
import { call, put, takeEvery, select, all, takeLatest } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { pageLoaded, pageLoadingError } from 'src/actions/common';
import { fetchMyWorkersRequest, loadProfileData } from 'src/components/Techs/data/actions';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { orderInfoLoaded } from 'src/actions/order';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { requestStarted, requestFinished, displayErrorsWithSnack } from 'src/utils/request';
import { ordersListStateSelector } from 'src/selectors/order';
import { orderWorkflowTypeIsEV, orderWorkFlowTypeIsEVInstallOnly } from 'src/containers/EV/utils';
import {
  reschedulePath,
  publicReschedulePath,
  push,
  replace,
  accountOrderStatusPath,
  accountOrderStatusPublicPath,
  evReschedulePath,
  evPublicReschedulePath,
} from 'src/utils/paths';
import { getStatusInfo } from 'src/utils/status';
import { techMyWorkersStateJsSelector, techProfileByIdJSSelector } from 'src/selectors/techs';
import { htToast } from 'HTKit/Toast';
import { FETCH_STATUSES } from 'src/components/Techs/data/constants';
import {
  PAGE_NAME,
  ACCEPT_STAND_BY_TIME,
  CANCEL_ORDER,
  DECLINE_STAND_BY_TIMES,
  SNACKBAR_NAME,
  UPDATE_RECURRING_SERVICE,
  CANCELLATION_TOAST_MESSAGES,
  REBOOK_ORDER,
} from './constants';

import {
  formattedRescheduleUrlSelector,
  cancelReasonsSelector,
  cancelReasonsRecurringOnlySelector,
} from './selectors';
import { authFailedErrorPageSnackNotice } from './actions';

/* Helper Functions */

export function* getOrderInfo({ orderId, orderToken, publicPage, forceFetch }) {
  const routes = yield call(selectRoutes);
  const orderList = yield select(ordersListStateSelector);

  /** @type {Order} */
  let orderInfo;
  if (publicPage) {
    orderInfo = orderList.find((order) => order.get('token') === orderToken);
  } else {
    orderInfo = orderList.find((order) => order.get('id') === orderId);
  }

  const shouldFetchOrderInfo = !orderInfo || !orderInfo.get('totalAmount') || forceFetch;
  if (shouldFetchOrderInfo) {
    let response;
    if (publicPage) {
      response = yield call(routes.orders.public.info, { orderToken });
    } else {
      response = yield call(routes.orders.info, { orderId }, { full: true });
    }
    const {
      data: { order },
      err,
    } = response;
    if (err) {
      yield put(pageLoadingError(PAGE_NAME, response));
      yield put(displayErrorsWithSnack(response));
      return null;
    }
    orderInfo = order;
  } else {
    orderInfo = orderInfo.toJS();
  }

  const { preferredTechId } = orderInfo;

  if (preferredTechId) {
    const techData = yield select(techProfileByIdJSSelector(preferredTechId));
    if (!techData) {
      const techResult = yield call(routes.techs.profile, { id: preferredTechId });
      if (!techResult.err) {
        const {
          data: { tech: techDataResult },
        } = techResult;
        yield put(loadProfileData({ techId: preferredTechId, data: techDataResult }));
      }
    }
  }
  return orderInfo;
}

function* getOrderCancellationFee({ orderId }) {
  if (!orderId) {
    return null;
  }

  const routes = yield call(selectRoutes);

  const response = yield call(routes.orders.cancellationFee, { orderId }, { full: true });
  const { data, err } = response;
  if (err) {
    yield put(pageLoadingError(PAGE_NAME, response));
    yield put(displayErrorsWithSnack(response));
    return null;
  }
  return data;
}

function* getCancelReasons() {
  let cancelReasons = yield select(cancelReasonsSelector);

  if (!cancelReasons.length) {
    const routes = yield call(selectRoutes);
    const queryParams = { recurring_service: 'only_non_recurring' };
    const response = yield call(routes.orders.cancelReasons, queryParams);

    const {
      data: { cancelReasons: responseCancelReasons },
      err,
    } = response;

    cancelReasons = err ? [] : responseCancelReasons;
    return cancelReasons;
  }

  return cancelReasons;
}

function* getCancelReasonsRecurringOnly() {
  let cancelReasons = yield select(cancelReasonsRecurringOnlySelector);

  if (!cancelReasons.length) {
    const routes = yield call(selectRoutes);
    const queryParams = { recurring_service: 'only_recurring', always_include_other: true };
    const response = yield call(routes.orders.cancelReasons, queryParams);

    const {
      data: { cancelReasons: responseCancelReasons },
      err,
    } = response;

    cancelReasons = err ? [] : responseCancelReasons;
    return cancelReasons;
  }

  return cancelReasons;
}

function* updateStatusRoute({ order, publicPage, panel, queryString = '' }) {
  const { id, token } = order;
  let currentPanel = panel;
  if (!currentPanel) {
    currentPanel = getStatusInfo({ order }).panel;
  }

  // Ensure queryString starts with '?' to format correctly.
  // Callers should pass queryString in the format '?foo=bar', not 'foo=bar'.
  const formattedQueryString = queryString?.startsWith('?') ? queryString : `?${queryString}`;

  const basePath = publicPage
    ? accountOrderStatusPublicPath(token, currentPanel)
    : accountOrderStatusPath(id, currentPanel);
  const newPath = `${basePath}${formattedQueryString}`;

  yield put(replace(newPath));
}

function* isPublicPage() {
  return yield select((state) => state.getIn(['pages', PAGE_NAME, 'publicPage']));
}
/*
 * Depending on the partner order status, we need to determine the pathing for the reschedule button.
 * Note: This will need an update as the same scheduling should be available for all orders, with diff configurations.
 *       An update in the future to make this a bit more robust.
 * Note: Fast hack for ev feature.
 * */

const getRescheduleUrl = ({ orderId, orderToken, publicPage, orderInfo }) => {
  const isEvWorkflow =
    orderWorkflowTypeIsEV(orderInfo) || orderWorkFlowTypeIsEVInstallOnly(orderInfo);
  if (isEvWorkflow) {
    return publicPage ? evPublicReschedulePath(orderToken) : evReschedulePath(orderId);
  }
  return publicPage ? publicReschedulePath(orderToken) : reschedulePath(orderId);
};

/*
  Account Order Page
*/

function* handleLoadPage(action) {
  const { orderId, orderToken, orderCardStatus, publicPage, queryString = '' } = action;
  if (!publicPage) {
    const userLoaded = yield loadUserProfileSaga(PAGE_NAME, true);
    if (!userLoaded) return;
  }

  const { orderInfo, cancellationData, cancelReasons, cancelReasonsRecurringOnly } = yield all({
    orderInfo: call(getOrderInfo, { orderId, orderToken, publicPage }),
    cancellationData: call(getOrderCancellationFee, { orderId }),
    cancelReasons: call(getCancelReasons),
    cancelReasonsRecurringOnly: call(getCancelReasonsRecurringOnly),
  });

  orderInfo.cancellationData = cancellationData?.data;

  if (orderInfo) {
    yield put(orderInfoLoaded({ order: orderInfo }));

    const { panel } = getStatusInfo({ order: orderInfo });
    if (orderCardStatus !== panel) {
      yield call(updateStatusRoute, { order: orderInfo, publicPage, panel, queryString });
    }

    if (orderInfo.hasAuthError) {
      yield put(
        authFailedErrorPageSnackNotice({
          isLoggedIn: !publicPage,
          hasSetPassword: orderInfo.hasSetPassword,
        }),
      );
    }

    const formattedRescheduleUrl = yield call(getRescheduleUrl, {
      orderId,
      orderToken,
      publicPage,
      orderInfo,
    });

    const { fetchStatus } = yield select(techMyWorkersStateJsSelector);

    // Order page route transitions trigger multiple LOAD_PAGE actions.
    // Only fetch worker data on initial load or after failure.
    if ([FETCH_STATUSES.IDLE, FETCH_STATUSES.FAILURE].includes(fetchStatus)) {
      yield put(fetchMyWorkersRequest());
    }

    yield put(
      pageLoaded(PAGE_NAME, {
        publicPage,
        formattedRescheduleUrl,
        cancelReasons,
        cancelReasonsRecurringOnly,
      }),
    );
  }
}

function* acceptStandByTime({ availabilityId, orderId, orderToken }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());

  const publicPage = yield call(isPublicPage);
  let response;

  if (publicPage) {
    response = yield call(
      routes.orders.public.acceptSuggestedAvailability,
      { orderToken },
      { availabilityId },
    );
  } else {
    response = yield call(
      routes.orders.acceptSuggestedAvailability,
      { orderId },
      { availabilityId },
    );
  }
  const {
    data: { order },
    err,
  } = response;
  if (err) {
    yield put(
      displayErrorsWithSnack(response, { pageName: PAGE_NAME, componentName: SNACKBAR_NAME }),
    );
  } else {
    yield put(orderInfoLoaded({ order }));
    yield call(updateStatusRoute, { order, publicPage });
  }
  yield put(requestFinished());
}

function* declineStandByTimes({ orderId, orderToken }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.orders.declineSuggestedAvailability, { orderId, orderToken });
  const { err } = response;
  if (err) {
    yield put(
      displayErrorsWithSnack(response, { pageName: PAGE_NAME, componentName: SNACKBAR_NAME }),
    );
    yield put(requestFinished());
  } else {
    yield put(requestFinished());
    const formattedRescheduleUrl = yield select(formattedRescheduleUrlSelector);
    yield put(push(formattedRescheduleUrl));

    const publicPage = yield call(isPublicPage);
    let orderResponse;
    if (publicPage) {
      orderResponse = yield call(routes.orders.public.info, { orderToken });
    } else {
      orderResponse = yield call(routes.orders.info, { orderId }, { full: true });
    }
    const {
      data: { order },
    } = orderResponse;
    yield put(orderInfoLoaded({ order }));
  }
}

function* handleCancelOrder(action) {
  const { payload } = action;

  const routes = yield call(selectRoutes);
  const { orderId, orderToken, cancelReasonId, otherReason, cancelRecurringService } = payload;

  yield put(requestStarted());

  const publicPage = yield call(isPublicPage);
  let response;

  const cancelParams = {
    cancelReasonId,
    otherReason,
    ...(cancelRecurringService && { cancelRecurringService }),
  };
  if (publicPage) {
    response = yield call(routes.orders.public.cancel, { orderToken }, cancelParams);
  } else {
    response = yield call(routes.orders.cancelOrder, { orderId }, cancelParams);
  }

  if (response.err) {
    yield put(
      displayErrorsWithSnack(response, { pageName: PAGE_NAME, componentName: SNACKBAR_NAME }),
    );
  } else {
    const { order } = response.data;
    yield put(orderInfoLoaded({ order }));
    yield call(updateStatusRoute, { order, publicPage });

    if (cancelRecurringService) {
      htToast(CANCELLATION_TOAST_MESSAGES.RECURRING_SERIES_CANCELLED);
    } else {
      htToast(CANCELLATION_TOAST_MESSAGES.APPOINTMENT_CANCELLED);
    }
  }
  yield put(requestFinished());
}

function* handleUpdateRecurringService(action) {
  const { recurrence, recurrenceId, orderToken, onSuccess, onError } = action.payload;
  const routes = yield call(selectRoutes);

  yield put(requestStarted());

  const response = yield call(
    routes.orders.updateRecurringService,
    { recurrenceId },
    { recurrence, order_token: orderToken },
  );

  if (response.err) {
    onError(response);
    const extractResponseErrors = () => {
      if (!response.data) return [];

      /** @type {string} */
      const mainError = get(response, 'data.error', '');
      /** @type {string[]} */
      const recurrenceErrorList = get(response.data, 'errors.recurrence', []);

      return [mainError, ...recurrenceErrorList].filter(Boolean);
    };
    const snackObject = { data: { errors: extractResponseErrors() } };
    yield put(
      displayErrorsWithSnack(snackObject, { pageName: PAGE_NAME, componentName: SNACKBAR_NAME }),
    );
  } else {
    onSuccess(response.data);
  }
  yield put(requestFinished());
}

function* handleRebookOrder(action) {
  const { order = null, onSuccess = () => {} } = action.payload || {};
  if (!order) return;

  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.orders.rebook, { orderId: order.id });
  yield put(requestFinished());

  if (response.err) {
    yield put(
      displayErrorsWithSnack(response, { pageName: PAGE_NAME, componentName: SNACKBAR_NAME }),
    );
    return;
  }
  const { cart } = response.data;
  yield put(updateCart({ cart, replace: true }));
  onSuccess({ cart });
}

function* accountOrderPageSaga() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === PAGE_NAME,
    handleLoadPage,
  );
  yield takeEvery(ACCEPT_STAND_BY_TIME, acceptStandByTime);
  yield takeEvery(CANCEL_ORDER, handleCancelOrder);
  yield takeEvery(DECLINE_STAND_BY_TIMES, declineStandByTimes);
  yield takeEvery(UPDATE_RECURRING_SERVICE, handleUpdateRecurringService);
  yield takeLatest(REBOOK_ORDER, handleRebookOrder);
}

export default [accountOrderPageSaga];

/** @typedef {import ('src/types/order').Order} Order */
