/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BlockUi from 'react-block-ui';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import classNames from 'classnames';
import { isArray } from 'lodash';
import Loader from 'src/components/Loader';
import HTGladlyChat from 'src/utils/Gladly/HTGladlyChat';
import { userSelector } from 'src/selectors/user';
import Snackbar from 'src/components/Snackbar';
import { DefaultMetaTags, CanonicalUrlTag } from 'src/components/SEO';
import ScrollToTop from 'src/components/ScrollToTop';
import Sidebar from 'src/components/Sidebar';
import Header from 'src/components/Header';
import NoticeStack from 'src/components/NoticeStack';
import { DefaultFooter } from 'src/components/Footer';
import PinnedContainer from 'src/components/Layout/PinnedContainer';
import ClaimStoreCreditsModal from 'src/components/ClaimStoreCreditsModal';
import FirstServiceDiscountModal from 'HTKit/Modals/FirstServiceDiscount';
import { splitReadySelector, splitErrorSelector } from 'src/components/SplitIO/selectors';
import { LoginModalManager, LoginPasscodeModal } from '../LoginPage/Modal';
import { loadCartPreview, appLoaded } from './actions';
import { withAppBaseEnhancements } from './withAppBaseEnhancements';

import styles from './styles.scss';

class AppBase extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    appLoaded: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    loadCartPreview: PropTypes.func.isRequired,
    page: PropTypes.object,
    main: PropTypes.object.isRequired,
    showFooter: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    pathname: PropTypes.string,
    adGroup: PropTypes.string,
    term: PropTypes.string,
    loginModal: PropTypes.object.isRequired, // eslint-disable-line
    phone: PropTypes.string,
    user: PropTypes.object,
    chatVisible: PropTypes.bool,
    splitIsReady: PropTypes.bool,
    splitIsError: PropTypes.bool,
  };

  UNSAFE_componentWillMount() {
    const { isMobile, isTablet, phone, pathname, adGroup, term, user } = this.props;
    this.props.loadCartPreview();
    this.props.appLoaded({
      user,
      isMobile,
      isTablet,
      phone,
      adwordPhone: true,
      pathname,
      adGroup,
      term,
    });
  }

  handleChatInitialization = () => {
    const { chatVisible, dispatch, pathname, user } = this.props;

    if (!pathname || !this.shouldShowChatWidget(pathname)) {
      return;
    }

    const initializeGladly = () => {
      HTGladlyChat.initializeAndStart({ dispatch, user }, () => {
        if (chatVisible) {
          HTGladlyChat.open();
        }
      });
    };

    initializeGladly();
  };

  componentDidMount() {
    const { phone } = this.props;
    if (phone) {
      Cookies.set('adword-phone', phone, { expires: 14 });
    }
    this.handleChatInitialization();
  }

  componentDidUpdate() {
    const modalOpen = this.props.loginModal.get('open');
    const absoluteHidden = document.body.classList.contains('absoluteHidden');

    if (modalOpen && !absoluteHidden) {
      document.body.classList.toggle('absoluteHidden');
    }

    if (!modalOpen && absoluteHidden) {
      document.body.classList.remove('absoluteHidden');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  shouldShowChatWidget(pathname) {
    const pathNamesThatShouldNotShowChat = ['xfinity-referral', 'xfinity-movers', 'contact-us'];
    let shouldShow = true;
    pathNamesThatShouldNotShowChat.forEach((bannedName) => {
      if (pathname.includes(bannedName)) {
        shouldShow = false;
      }
    });
    return shouldShow;
  }

  /**
   * Two visually different loading displays depending what the app is waiting to load.
   * @returns {*[]}
   */
  isPageLoadingAttributes() {
    const { page, splitIsReady: splitLoading, splitIsError } = this.props;
    const pageLoading = page ? page.get('loading') : false;

    if (splitIsError) return [false, ''];
    if (!splitLoading) return [true, styles.blockUIContainerSplitio];
    if (pageLoading) return [true, styles.blockUIContainer];

    return [false, ''];
  }

  render() {
    const { showFooter, main } = this.props;
    const [loading, blockUiStyle] = this.isPageLoadingAttributes();
    const mainWrapperClasses = classNames({
      [styles.mainWrapper]: true,
      [styles.hideFooter]: !showFooter,
      [styles.bgNavy050]: main.get('withNavy050BG'),
      [styles.bgNavy050Mobile]: main.get('withNavy050BGMobile'),
      [styles.bgPaloAlto]: main.get('withPaloAltoBG'),
    });
    const containerStyles = classNames({
      [styles.appContainer]: true,
    });

    return (
      <ScrollToTop>
        <div className={containerStyles}>
          <DefaultMetaTags />
          <CanonicalUrlTag />
          <BlockUi tag="div" className={blockUiStyle} blocking={loading} loader={<Loader />} />
          <NoticeStack />
          <Sidebar />
          <div className={mainWrapperClasses} id="ht_main_app_wrapper">
            <Header />
            <Snackbar type="fixed" />
            <div className={styles.mainContentWrapper}>{this.props.children}</div>
            <div className={styles.footerWrapper}>
              <DefaultFooter />
            </div>
            <PinnedContainer />
          </div>
          <LoginModalManager />
          <LoginPasscodeModal />
          {/* DEPRECATED: FirstServiceDiscountModal is deprecated and will be removed in future versions. */}
          <FirstServiceDiscountModal isOpen />
          <ClaimStoreCreditsModal />
        </div>
      </ScrollToTop>
    );
  }
}

function mapStateToProps(state) {
  const search = state.getIn(['router', 'location', 'search']);
  let { phone, utm_adgroup, utm_term, chat } = queryString.parse(search); // eslint-disable-line
  if (isArray(phone)) {
    phone = phone[0];
  }
  return {
    isMobile: state.get('isMobile'),
    isTablet: state.get('isTablet'),
    page: state.getIn(['pages', 'current'], {}),
    main: state.getIn(['layout', 'main']),
    showFooter: state.getIn(['layout', 'footer', 'show']),
    pathname: state.getIn(['router', 'location', 'pathname']),
    adGroup: utm_adgroup,
    term: utm_term,
    phone,
    loginModal: state.getIn(['components', 'loginModal']),
    user: userSelector(state),
    chatVisible: typeof chat !== 'undefined',
    splitIsReady: splitReadySelector(state),
    splitIsError: splitErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCartPreview: () => dispatch(loadCartPreview()),
    appLoaded: (args) => dispatch(appLoaded(args)),
    dispatch,
  };
}

const AppBaseWithEnhancements = withAppBaseEnhancements(AppBase);

const AppBaseWrapper = (props) => {
  return <AppBaseWithEnhancements {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBaseWrapper);
