import { useSelector } from 'react-redux';
import { pureCartSelector } from 'src/selectors/cart';
import { myWorkerCategoriesJSSelector, suggestedWorkersDataJsSelector } from 'src/selectors/techs';
import { memoizedCreateCartFlow, DEFAULT_CONFIG_VALUES } from './cartFlowFactory';
import { getDirectBookingTechId } from '../cookies/directBookingCookie';

export const useCartFlow = () => {
  const cart = useSelector(pureCartSelector);
  const suggestedWorkers = useSelector(suggestedWorkersDataJsSelector);
  const myWorkers = useSelector(myWorkerCategoriesJSSelector);

  /** Check if this booking was initiated from a worker's landing page */
  const directBookingTechId = getDirectBookingTechId();

  if (!cart) {
    return DEFAULT_CONFIG_VALUES;
  }

  return memoizedCreateCartFlow({
    cart,
    suggestedWorkers,
    myWorkers,
    isDirectBooking: Boolean(directBookingTechId),
  });
};
